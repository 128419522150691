import React, { Component } from "react";
import TransactionItem from "./TransactionItem";

export default class ListTransactions extends Component {

  render() {
    return (
      <div className="container">

        <div className="col-xs-12">
          <ul className="-transactionslist">
            {this.props.transactions.map((transaction, index) =>
            (
              <TransactionItem
                key={transaction.authorizationCode + index}
                transaction={transaction}
                showModal={this.props.showModal}
                visibleButtonCancellation={this.props.visibleButtonCancellation}
              />
            )
            )}

          </ul>
          {!this.props.loadingTransactions &&
            <div className="-loadingtransactions">Buscando transações...</div>
          }
        </div>
      </div>
    );
  }
}


