import React, { Component } from "react";

export default class OptionsTransactions extends Component {

  render() {

    return (
      <div className="digitaltransactions-filter">
        <div className="-transactionsoptions">
          <div className="container">
            <div className="col-xs-12">
              <div className="-financialfilter js-financial -active">
                {(this.props.typeSearch === 'PAYMENTS' || this.props.typeSearch === 'RECEIVABLES') &&
                  <div className="-containeractions">
                    {this.props.permissionPaymentsMarketplace &&
                      <button
                        className={"btn " + (this.props.typeComission === 'MARKETPLACE' ? '-active' : '')}
                        onClick={() => this.props.updateComissionType("MARKETPLACE")}>
                        <img src="./../icon/ic-plus.png" alt="" className="-iconbtn" />
                        <span>Esfera</span>
                      </button>
                    }
                    <button
                      className={"btn " + (this.props.typeComission === 'SELLER' ? '-active' : '')}
                      onClick={() => this.props.updateComissionType("SELLER")}>
                      <img src="./../icon/ic-trophy.png" alt="" className="-iconbtn" />
                      <span>Parceiro</span>
                    </button>
                  </div>
                }
              </div>
              <div className="-transactionsfilter js-transactions -active">
                <div className="-containerboxs">
                  {this.props.typeSearch === 'SALE' &&
                    <div className="-boxinfo">
                      <p>Total vendas do período</p>
                      <span>{this.props.totalizers.totalAmountOfSoldTransactions}</span>
                      <p>Quantidade</p>
                      <span>{this.props.quantityTransactions} {this.props.quantityTransactions > 1 ? 'transações' : 'transação'}</span>
                    </div>
                  }
                  {(this.props.typeSearch === 'PAYMENTS' || this.props.typeSearch === 'RECEIVABLES') &&
                    <div className="-groupboxs">
                      <div className="-boxinfo">
                        <p>Comissão</p>
                        {this.props.typeComission === 'MARKETPLACE' && this.props.permissionPaymentsMarketplace &&
                          <span>{this.props.totalizers.totalAmountOfEsferaLiquidationTransactions}</span>
                        }
                        {this.props.typeComission === 'SELLER' &&
                          <span>{this.props.totalizers.totalAmountOfPartnerLiquidationTransactions}</span>
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
