import React, { Component } from "react";;

export default class Filter extends Component {
    constructor(props) {
        super(props);

        this.isValid = this.isValid.bind(this);
    }

    isValid() {
        const valid = this.props.filterDateInit ||
            this.props.filterDateFrom ||
            this.props.filterOrderId ||
            this.props.filterCommerceItemId ||
            this.props.filterState;

        return valid;
    }
    render() {
        return (
            <>
                {this.props.visibleFilter &&
                    <div className="-container-filter-transactions">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">

                                    <button type="button" className="close -btn-close-filter js-closefilter-saleoff" onClick={this.props.closeFilter}>
                                        <span aria-hidden="true">×</span>
                                    </button>

                                    <form className="-form-filter">
                                        <div className="-container-inputs">
                                            <div className="-input-label">
                                                <label className="-label-text">Período: </label>
                                                <div>
                                                    <input
                                                        type="date"
                                                        name="date-start"
                                                        id="date-start"
                                                        className="input-custom"
                                                        value={this.props.filterDateInit}
                                                        onChange={this.props.handleDateInit}
                                                        max="9999-12-31"
                                                    />
                                                    <input
                                                        type="date"
                                                        name="date-end"
                                                        id="date-end"
                                                        className="input-custom"
                                                        value={this.props.filterDateFrom}
                                                        onChange={this.props.handleDateFrom}
                                                        max="9999-12-31"
                                                    />
                                                </div>
                                            </div>

                                            <div className="-input-group">
                                                <div className="-input-label">
                                                    <label className="-label-text">Pedido:</label>
                                                    <input
                                                        type="text"
                                                        name="ordernumber"
                                                        id="ordernumber"
                                                        className="-input-text"
                                                        value={this.props.filterOrderId}
                                                        onChange={this.props.handleOrder}
                                                        placeholder="Número do pedido"
                                                    />
                                                </div>

                                                <div className="-input-label">
                                                    <label className="-label-text">Item:</label>
                                                    <input
                                                        type="text"
                                                        name="itemnumber"
                                                        id="itemnumber"
                                                        className="-input-text"
                                                        placeholder="Número do item"
                                                        value={this.props.filterCommerceItemId}
                                                        onChange={this.props.handleCommerceItem}
                                                    />
                                                </div>
                                            </div>

                                            <div className="-container-radio-button">
                                                <label className="-label-text">Status: </label>

                                                <div className="-group-radio-button">
                                                    <div className="-radio-button" onClick={this.props.handleState}>
                                                        <input
                                                            type="radio"
                                                            name="status"
                                                            id="A"
                                                            value="complete"
                                                        />
                                                        <label className={this.props.filterState === "complete" ? "btn-radio active" : "btn-radio"}>
                                                            COMPLETE
                                                        </label>
                                                    </div>

                                                    <div className="-radio-button" onClick={this.props.handleState}>
                                                        <input
                                                            type="radio"
                                                            name="status"
                                                            value="waiting_release"
                                                            id="B" />
                                                        <label className={this.props.filterState === "waiting_release" ? "btn-radio active" : "btn-radio"}>
                                                            WAITING_RELEASE
                                                        </label>
                                                    </div>

                                                    <div className="-radio-button" onClick={this.props.handleState}>
                                                        <input
                                                            type="radio"
                                                            name="status"
                                                            value="waiting_release_getnet"
                                                            id="C" />
                                                        <label className={this.props.filterState === "waiting_release_getnet" ? "btn-radio active" : "btn-radio"}>
                                                            WAITING_RELEASE_GETNET
                                                        </label>
                                                    </div>

                                                    <div className="-radio-button" onClick={this.props.handleState}>
                                                        <input
                                                            type="radio"
                                                            name="status"
                                                            value="failed"
                                                            id="D" />
                                                        <label className={this.props.filterState === "failed" ? "btn-radio active" : "btn-radio"}>
                                                            FAILED
                                                        </label>
                                                    </div>

                                                    <div className="-radio-button" onClick={this.props.handleState}>
                                                        <input
                                                            type="radio"
                                                            name="status"
                                                            value="need_release"
                                                            id="E" />
                                                        <label className={this.props.filterState === "need_release" ? "btn-radio active" : "btn-radio"}>
                                                            NEED_RELEASE
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="-container-button">
                                            <button type="button" className="btn-esfera -secondary -btn-apply-filter" disabled={!this.isValid()} onClick={this.props.submitFilter}>Aplicar Filtros</button>
                                            <button type="button" className="-btn-filter-clear" onClick={this.props.clearFilter}>Limpar Filtros</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}


