

import React, { Component } from "react";
import Parser from 'html-react-parser';

export default class Messages extends Component {
    constructor(props) {
        super(props);

    }
    render() {

        return (
            <>
                {(this.props.messageSuccess || this.props.messageFail) &&
                    <div className="container">
                        <div className="-containermessages">
                            {this.props.messageSuccess &&
                                <div className="alert alert-success" role="alert">
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.props.closeMessage}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <div className="-alertmessagecontainer">
                                        <span className="-icomsg -icosuccess"></span>
                                        <span className="-alertmessage">{Parser(this.props.descriptionMessage)}</span>
                                    </div>
                                </div>
                            }
                            {this.props.messageFail &&
                                <div className="alert alert-danger" role="alert">
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.props.closeMessage}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <div className="-alertmessagecontainer">
                                        <span className="-icomsg -icoerror"></span>
                                        <span className="-alertmessage">{Parser(this.props.descriptionMessage)}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </>
        )
    }
}