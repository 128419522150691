import React, { Component } from "react";

export default class Modal extends Component {
    constructor(props) {
        super(props);

    }
    render() {

        return (
            <>
                {this.props.showModal &&
                    <div className="-mdl-upload-file js-mdl-upload-file">
                        <div className="util-modal-background">
                            <div className="util-modal-center -modalbox">

                                <div className="-modalheader">
                                    <h5 className="-title" id="uploadFile">Confirmação</h5>
                                    <div className="js-closemdl-upload-file" onClick={this.props.closeModal}>
                                        <span className="icon-x"></span>
                                    </div>
                                </div>

                                <div className="-modalcontent">
                                    <div className="-containernoticewarning">
                                        <p className="-textmain">Confirma o envio do arquivo abaixo:</p>
                                        <p className="-text"><span>Data: </span><span>{this.props.date}</span></p>
                                        <p className="-text"><span>Arquivo: </span>{this.props.csvName}</p>
                                    </div>

                                    <div className="-containerbuttons">
                                        <button type="button" className="btn" onClick={this.props.submitForm}>SIM</button>
                                        <button type="button" className="btn" onClick={this.props.closeModal}>NÃO</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}