import React, { Component } from "react";
import InputMask from "react-input-mask";

export default class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeFilter: false,
      typeTransactions: [
        {
          id: "vendas",
          name: "Vendas",
          typeSearch: "SALE",
          permission: true
        },
        {
          id: "pagamentos",
          name: "Pagamentos",
          typeSearch: "PAYMENTS",
          permission: true,
          typeComission: props.permissionPaymentsMarketplace ? "MARKETPLACE" : "SELLER"
        },
        {
          id: "recebiveis",
          name: "Recebíveis",
          typeSearch: "RECEIVABLES",
          permission: true,
          typeComission: props.permissionPaymentsMarketplace ? "MARKETPLACE" : "SELLER"
        }
        // ,{
        //   id: "chargeback",
        //   name: "Chargeback",
        //   typeSearch: "CHARGEBACK",
        //   permission: this.props.permissionChargeback
        // }
      ]
    };

    this.toggleFilter = this.toggleFilter.bind(this);
  }

  toggleFilter() {
    this.setState({
      activeFilter: !this.state.activeFilter
    });
  }


  render() {
    return (
      <div className="digitaltransactions-filter">
        <div className="-typetransactions">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <div className="-filterwrapper">
                  <div className="-btnwrapper js-btn-wrapper">
                    {this.state.typeTransactions.map((type, index) =>
                      <button
                        key={'type-' + index}
                        type="button"
                        className={'btn' +
                          (this.props.typeTransactionsActive.name === type.name ? ' -active' : '') +
                          (type.permission ? '' : ' util-hidden')
                        }
                        onClick={() => this.props.updateTransactionType(type)}>
                        {type.name}
                      </button>
                    )}
                  </div>
                  <div className="js-btn-filter -btnfiltercontainer" onClick={this.toggleFilter}>
                    <div className="-btnfilter">
                      <i className="icon-ic-filter -iconfilter"></i>
                      <span className="-textfilter">Filtros</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"-filteractions js-filteractions" + (this.state.activeFilter ? " -active" : "")}>
          <div className="container">
            <div className="col-xs-12">
              <div className="-headerfilteractions">
                <button type="button" className="close js-closefilter" onClick={this.toggleFilter}>
                  <span aria-hidden="true">×</span>
                </button> </div>
              <div className="-contentfilteractions">
                <div className="-filters">
                  <div className="-periodfilter">
                    <strong>Período</strong>

                    <div className="-period">
                      <div className="-forminput">
                        <InputMask
                          mask="99/99/9999"
                          className="msk-date"
                          placeholder="Início"
                          id="dateFrom"
                          value={this.props.dateFrom}
                          onChange={this.props.filterFrom}
                        />
                        <img src="../icon/calendar.svg" alt="" />
                      </div>

                      <div className="-forminput">
                        <InputMask
                          mask="99/99/9999"
                          className="msk-date"
                          placeholder="Fim"
                          id="dateTo"
                          value={this.props.dateTo}
                          onChange={this.props.filterTo}
                        />
                        <img src="../icon/calendar.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="-actionfilter">
                  <button className="btn" onClick={this.props.apply}>Aplicar</button>
                  <div className="-clearfilter" onClick={this.props.clear}>Limpar Filtros</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
