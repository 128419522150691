import React, { Component } from "react";;

export default class OptionsAction extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        return (
            <div className="-typetransactions">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="-container-header">
                                <div className="-group-btn-header">
                                    <button type="button" className="btn -active js-saleoff">Transação</button>
                                    <button type="button" className="btn" onClick={this.props.downloadCSV}>
                                        <img src="../ic-changed.png" alt="" className="-iconbtn" />
                                        <span>Exportar</span>
                                    </button>
                                </div>
                                <button className="-btnfilter js-btn-filter-saleoff" onClick={this.props.toggleFilter}>
                                    <i className="icon-ic-filter -iconfilter"></i>
                                    <span className="-textfilter">Filtros</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


