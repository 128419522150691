import React, { Component } from 'react';
import axios from 'axios';
import { stateToHTML } from 'draft-js-export-html';

import Editor from '../textEditor/RichEditorPopulated';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import setEditorValidated from '../textEditor/RichEditorActions';

import {
  generalError, 
  termSuccessUpdated,
  termFieldInvalid
} from '../../helpers/GlobalMessages'

const initialState = {
  disabled_button: false,

  text_editor_content: '',
  term_error: ''
};

export class ConfigTerms extends Component {
  state = initialState;

  componentDidMount() {
    const BASE_URL = `${window.REACT_APP_URL}`;

    axios
      .get(`${BASE_URL}/termo-condicao`)
      .then((response) => {
        if (response.data.results) {
          this.setState({
            text_editor_content: response.data.results.description,
          });
        } else {
          this.setState({
            text_editor_content: '',
          });
        }
      })
      .catch((error) => {
        if (error.response && JSON.stringify(error.response.status) === '401') {
          this.props.logout();
        }
      });
      this.props.setEditorValidated(false);
  }

  validade = () => {
    let term_error = '';
    
    if (this.props.editorValidated === false) {
      term_error = termFieldInvalid();
    }

    if (term_error) {
      this.setState({
        term_error
      });
      return false;
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const isValid = this.validade();

    if (isValid) {
      const BASE_URL = `${window.REACT_APP_URL}`;

      if (this.state.text_editor_content != '') {
        const termoCondicao = {
          description: this.state.text_editor_content,
        };

        axios.put(`${BASE_URL}/termo-condicao`, termoCondicao)
          .then(() => {
            this.setState(initialState);
            document.getElementById('form-container').reset();
            this.props.handleSendFormTermsSuccess(termSuccessUpdated());
          })
          .catch((error) => {
            this.setState(initialState);

            if (error.response) {
              const httpStatusError = JSON.stringify(error.response.status);
              if (httpStatusError === '401') {
                this.props.logout();
                return;
              } else {
                const message = error.response?.data?.results?.userMessage;
                if (message) {
                  this.props.handleSendFormTermsFail(message);
                  return;
                }
              }
            }
            this.props.handleSendFormTermsFail(generalError());
          });
      }
    }
  };

  handleTextEditorChange = (text_editor_content) => {
    this.setState({
      text_editor_content: stateToHTML(text_editor_content.getCurrentContent()),
    });
  };

  render() {
    return (
      <div id='page-wrapper'>
        <form
          id='form-container'
          className='container'
          onSubmit={this.handleSubmit.bind(this)}
        >
          <div className='input-block'>
            <label htmlFor='deadline'>Termos e Condições</label>
            <Editor
              handleTextEditorChange={this.handleTextEditorChange}
              ref='draftRef'
              contentHtml={this.state.text_editor_content}
            />
          </div>
          <div className='error-msg'>{this.state.term_error}</div>

          <div className='button-wrapper'>
            <button
              type='submit'
              className='button-admin'
              id='send-form'
              disabled={this.state.disabled_button}
            >
              {this.state.disabled_button ? 'Enviando...' : 'Enviar'}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ editorValidated: state.editor.editorValidated });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setEditorValidated }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ConfigTerms);
