import axios from "axios";
const BASE_URL = `${window.REACT_APP_URL}`;
const BASE_URL_MDI = `${window.REACT_APP_URL_MDI}`;

function getListImport() {
  const response = axios.get(`${BASE_URL}/importacao`);
  return {
    type: "LIST_IMPORTS_FETCHED",
    payload: response,
  };
}

function getListAllImports() {
  const response = axios.get(`${BASE_URL}/importacao/all`);
  return {
    type: "LIST_IMPORTS_FETCHED",
    payload: response,
  };
}

function getListAllImportsMDI() {
  const response = axios.get(`${BASE_URL_MDI}/cashback-files`);
  return {
    type: "LIST_IMPORTS_FETCHED",
    payload: response,
  };
}

function filterListImport(newtable) {
  return {
    type: "LIST_TABLE_FILTERED",
    payload: newtable,
  };
}

export {getListImport,getListAllImports,getListAllImportsMDI,filterListImport}