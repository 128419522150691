import React, { Component } from "react";;

export default class List extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        return (
            <div className="-container-transactions table-responsive">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="-order-table">
                                {this.props.releases.length === 0 &&
                                    <h4>Nenhuma transação encontrada</h4>
                                }
                                {this.props.releases.length > 0 &&
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>CommerceItem</th>
                                                <th>Pedido</th>
                                                <th>Documento</th>
                                                <th>Solicitação Liquidação</th>
                                                <th>Valor</th>
                                                <th>
                                                    <div className="-col-actions">
                                                    Status
                                                    <div className="-info-container-tooltip js-open-tooltip-cancel">
                                                        <span className="-info">i</span>
                                                        <div className="-info-tooltip js-tooltip-cancel util-hidden status">
                                                        <span className="-msg-tooltip">
                                                            COMPLETE = Liquidado
                                                            <br></br>
                                                            WAITING_RELEASE = Aguardando Liberação
                                                            <br></br>
                                                            WAITING_RELEASE_GETNET = Aguardando Liberação GetNet
                                                            <br></br>
                                                            FAILED = Falha na Liquidação
                                                            <br></br>
                                                            NEED_RELEASE = Não Liberado
                                                            
                                                        </span>
                                                        </div>
                                                    </div>
                                                    </div>

                                                </th>
                                                <th>
                                                    <div className="-col-actions">
                                                    Ação
                                                    <div className="-info-container-tooltip js-open-tooltip-cancel">
                                                        <span className="-info">i</span>
                                                        <div className="-info-tooltip js-tooltip-cancel util-hidden">
                                                        <span className="-msg-tooltip">
                                                            Cancelamento das liberações agendadas
                                                        </span>
                                                        </div>
                                                    </div>
                                                    </div>

                                                </th>
                                                {/* <th>
                                    <div className="-col-actions">
                                        Ação
                                        <div className="-info-container-tooltip js-open-tooltip-cancel">
                                            <span className="-info">i</span>
                                            <div className="-info-tooltip js-tooltip-cancel util-hidden">
                                                <span className="-msg-tooltip">
                                                    Cancelamento das liberações agendadas
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                </th> */}
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.props.releases.map((release, index) =>
                                                <tr key={index}>
                                                    <td>{release.commerceItemId}</td>
                                                    <td>{release.orderId}</td>
                                                    <td>{release.identifier}</td>
                                                    <td>{release.paymentDateRelease}</td>
                                                    <td>{release.itemValue}</td>
                                                    <td>{release.status}</td>
                                                    <td>
                                                        {release.showCheckbox && 
                                                            <input type="checkbox" name="cancel-transaction"
                                                            className="input-checkbox js-cancel-transaction" value={index} onClick={this.props.handleCancelSelection}/>
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                }
                            </div>

                            {/* <div className="-container-cancel-button">
                    <button className="btn-esfera -secondary -btn-cancel js-openmodalcancellation-saleoff">
                        Cancelar selecionados
                    </button>
                </div> */}
                            {/* 
                <nav className="-paginationcontainer">
                    <ul className="pagination">
                        <li className="active"><a href="#">1</a></li>
                        <li className=""><a href="#">2</a></li>
                        <li className=""><a href="#">3</a></li>
                        <li className="">
                            <a href="#" className="-nextpagination">Próximo</a>
                        </li>
                    </ul>
                </nav> */}
                    
                           {this.props.children}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}