import React, { Component } from "react";
export default class Modal extends Component {

  render() {
    return (
      <div className={"mdlcancellation js-mdlcancellation" + (this.props.visibleModal ? '' : " util-hidden")}>
        <div className="util-modal-background">
          <div className="util-modal-center -modalbox">

            <div className="-modalheader">
              <h5 className="-title" id="cancelletionConfirmation">Cancelamento</h5>
              <div className="js-closemdlcancellation" onClick={this.props.closeModal}>
                <span className="icon-x"></span>
              </div>
            </div>

            <div className="-modalcontent">
              <div className="-containernoticewarning">
                <p className="-textmain">Confirma o cancelamento do item/pedido abaixo:</p>
                <ul>
                  {this.props.arrayToModal.map((item, index) =>
                  (
                    <li key={'item-modal-' + index}>
                      <p className="-text"><span>Item: </span>{item.itemIdSku}</p>
                      <p className="-text"><span>Parceiro: </span>{item.subsellerDescription}</p>
                    </li>
                  )
                  )}
                </ul>
              </div>

              <div className="-containerbuttons">
                <button type="button" className="btn" onClick={this.props.confirmCancellation}>Sim</button>
                <button type="button" className="btn" onClick={this.props.closeModal}>Não</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


