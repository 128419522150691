export const validadeCpfOrCnpj = (str) => {
    if (str) {
        const length = str.length;
        var new_str = str;

        var i;
        if (length === 11 || length === 14 || length === 0 || length > 14) {
            return new_str;
        }
        else if (length >= 12 && length < 14) {
            if (length < 14) {
                for (i = 0; i < 14 - length; i++) {
                    new_str = '0'.concat(new_str);
                }
            }
            return new_str;
        }
        else if (length >= 1 && length < 11) {
            if (length < 11) {
                for (i = 0; i < 11 - length; i++) {
                    new_str = '0'.concat(new_str);
                }
            }
            return new_str;
        }
    }
}

export const validateCpfFunction = (cpf) => {
    if (cpf) { 
        var sum;
        var remaining;
        var i;
        sum = 0;

      if (cpf.match(/^(\d)\1{10}/g)) return false;

      for (i=1; i<=9; i++) sum = sum + parseInt(cpf.substring(i-1, i)) * (11 - i);
      remaining = (sum * 10) % 11;
    
        if ((remaining == 10) || (remaining == 11))  remaining = 0;
        if (remaining != parseInt(cpf.substring(9, 10)) ) return false;
    
      sum = 0;
        for (i = 1; i <= 10; i++) sum = sum + parseInt(cpf.substring(i-1, i)) * (12 - i);
        remaining = (sum * 10) % 11;
    
        if ((remaining == 10) || (remaining == 11))  remaining = 0;
        if (remaining != parseInt(cpf.substring(10, 11) ) ) return false;

        return true;
    }
}

export const validateCampaigns = (objectCampaigns, campaignCode, campaignName) => {
    for (var i = 0; i < objectCampaigns.length; i++) {
        if (
            objectCampaigns[i]['Campaign Term'] === undefined &&
            objectCampaigns[i]['Customer_ID'] === undefined &&
            objectCampaigns[i]['E-mail'] === undefined &&
            objectCampaigns[i]['MOBILE_PHONE_AREA_CODE'] === undefined &&
            objectCampaigns[i]['MOBILE_PHONE_COUNTRY_CODE'] === undefined &&
            objectCampaigns[i]['MOBILE_PHONE_NUMBER'] === undefined &&
            objectCampaigns[i]['NAME'] === undefined &&
            objectCampaigns[i]['Product_ID'] === undefined &&
            objectCampaigns[i]['Purchase Data'] === undefined &&
            objectCampaigns[i]['Total of points'] === undefined &&
            objectCampaigns[i]['Transaction Amount'] === undefined
        ) {
            objectCampaigns.splice(objectCampaigns.indexOf(i), 1);
        } else {
            objectCampaigns[i]['Customer_ID'] = validadeCpfOrCnpj(objectCampaigns[i]['Customer_ID']);
            objectCampaigns[i]['Campaign Code'] = campaignCode;
            objectCampaigns[i]['Campaign Name'] = campaignName;
        }
    }
    return objectCampaigns;
}

export const scrollTop = () => window.scrollTo(0, 0);

export const comparatorDates = (startDate, deadline) => {
    const start = new Date(startDate);
    const final = new Date(deadline);

    return (start.getTime() > final.getTime());
};

export const validateEmailFormat = (email) => (/^[\w\.-]+@([\w\-]+\.)+[a-zA-Z]{2,4}$/.test(email));


export const cnpj = (v) => {
    v = v.replace(/\D/g, "")                           //Remove tudo o que não é dígito
    v = v.replace(/^(\d{2})(\d)/, "$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/(\d{4})(\d)/, "$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    return v
};

export const cpf = (v) => {
    v = v.replace(/\D/g, "")                    //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
    return v
}