import React, { Component } from "react";

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import setPagination from "../utils/PaginationActions";

class UserFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };

        this.handleUserFilters = this.handleUserFilters.bind(this);
        this.handleResetPagination = this.handleResetPagination.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
    }

    toggleCheckbox () {
        this.setState({ active: !this.state.active });
    }

    handleUserFilters () {
        this.handleResetPagination();
        this.toggleCheckbox();

        if (this.props.tableFilter == "normal") {
            this.props.setDatatable(this.props.userFilter);
            this.props.setTableFilter('user');
            return;
        }
        
        if (this.props.tableFilter == "user") {
            this.props.getDefaultTable()
            .catch((error) => {
                if (error.response && JSON.stringify(error.response.status) === '401') {
                  this.props.keycloak.logout();
                }
            });
            this.props.setTableFilter('normal');
            return;
        }
    
        if (this.props.tableFilter == "date") {
            this.props.getDefaultTable().then(() => {
                setTimeout(() => {
                    this.props.setDatatable(this.props.userAndDateFilter);
                    this.props.setTableFilter('user+date');
                },10);
                return;
            })
            .catch((error) => {
                if (error.response && JSON.stringify(error.response.status) === '401') {
                  this.props.keycloak.logout();
                }
            });
        }
        
        if (this.props.tableFilter == "user+date") {
            this.props.getDefaultTable().then(() => {
                setTimeout(() => {
                    this.props.setDatatable(this.props.dateFilter);
                    this.props.setTableFilter('date');
                },10);
                return;
            })
            .catch((error) => {
                if (error.response && JSON.stringify(error.response.status) === '401') {
                  this.props.keycloak.logout();
                }
            });
        }
    }

    handleResetPagination() {
        this.props.setPagination(1);
        document.getElementById('pagination-manual-number').value = 1;
    }

    render() {
        return (
            <span className="d-inline custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="user-filter-checkbox" checked={this.state.active} onChange={this.handleUserFilters}/>
                <label className='custom-control-label filter-top-table' htmlFor='user-filter-checkbox'>Meus Arquivos</label>
            </span>
        );
    }
}

const mapStateToProps = (state) => ({ actualPage: state.pagination.actualPage });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setPagination }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UserFilter);