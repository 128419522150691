import {combineReducers} from 'redux'

import TableReducer from '../components/table/TableReducers'
import UploadReducer from '../components/upload/UploadReducers'
import DownloadPadraoReducer from '../components/download/DownloadReducers'
import FilterReducer from '../components/table/filters/FilterReducer'
import PaginationReducer from '../components/table/utils/PaginationReducer'
import RichEditorReducer from '../components/textEditor/RichEditorReducer'
import SelectReducers from '../components/campaigns/categories/reduce/SelectReducer'

const rootReducer = combineReducers({
    table: TableReducer, 
    upload: UploadReducer,
    downloadPadrao: DownloadPadraoReducer,
    filter: FilterReducer,
    pagination: PaginationReducer,
    editor: RichEditorReducer,
    select: SelectReducers
})

export default rootReducer