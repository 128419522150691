import React, { Component } from "react";
import UserHomeView from "../../../views/user/UserHomeView";
import AdminHomeView from "../../../views/admin/AdminHomeView";
import Modal from "../../../components/modal/Modal";

export default class HomeOptions extends Component {

  render() {
    return (
      <>
        {this.props.state.isAdmin || this.props.state.isAdminCashbackFiles || this.props.state.isPartner || this.props.state.isBackoffice || this.props.state.isBackofficeCashbackFiles ?
          <AdminHomeView
            isAdmin={this.props.state.isAdmin}
            isAdminCashbackFiles={this.props.state.isAdminCashbackFiles}
            isBackofficeCashbackFiles={this.props.state.isBackofficeCashbackFiles}
            isPartner={this.props.state.isPartner}
            isBackoffice={this.props.state.isBackoffice}
            keycloak={this.props.state.keycloak}
            logout={this.props.logout}
            name={this.props.state.name}
            email={this.props.state.email}
            site={this.props.site}
          />
          :
          <UserHomeView
            idAdmin={this.props.state.isAdmin}
            keycloak={this.props.state.keycloak}
            logout={this.props.logout}
            name={this.props.state.name}
            email={this.props.state.email}
            company={this.props.state.company}
          />
        }
        {this.props.state.modal && this.props.state.tipo == 'loading' ? (
          <Modal
            modal={this.props.state.modal}
            tipo={this.props.state.tipo}
          />
        ) : null}
      </>
    );
  }
}