import React, { Component } from "react";

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import setPagination from "../utils/PaginationActions";
import {setCampaignCode} from "./FilterActionss";
import {getListAllImports} from "../TableActions";

class ProcessedFilesFilter extends Component {
    constructor (props) {
        super(props);
        this.state = {
            active: true,
        };

        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.handleResetPagination = this.handleResetPagination.bind(this);
        this.handleProcessedFilesFilter = this.handleProcessedFilesFilter.bind(this);
    }

    toggleCheckbox () {
        this.setState({ active: !this.state.active });
        this.props.setCampaignCode('');
        this.props.getListAllImports();
    }

    handleResetPagination () {
        this.props.setPagination(1);
        document.getElementById('pagination-manual-number').value = 1;
    }

    handleProcessedFilesFilter () {
        this.props.setDatatable(this.props.processedFilesFilter);
    }

    componentDidMount() {
        this.handleProcessedFilesFilter();
    }

    render() {
        return (
            <span className="d-inline custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="user-filter-checkbox" checked={this.state.active} onChange={this.toggleCheckbox}/>
                <label className='custom-control-label filter-top-table' htmlFor='user-filter-checkbox'>Arquivos Processados</label>
            </span>
        );
    }
}

const mapStateToProps = (state) => ({ actualPage: state.pagination.actualPage });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setPagination, setCampaignCode, getListAllImports }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProcessedFilesFilter);