const INITIAL_STATE = {editorValidated: false}

export default function (state = INITIAL_STATE, action){
    try{
        switch (action.type) {
            case 'RICH_EDITOR_VALIDATED':
                return {...state, editorValidated: action.payload}
            default:
                return state;
        }
    }catch(e){
        return state;
    }
}