function setTableFilter(newValue) {
    return {
        type: "ACTUAL_TABLE_FILTER",
        payload: newValue,
    };
}

function setCampaignCode(newValue) {
    return {
        type: "CAMPAIGN_CODE_FILTER",
        payload: newValue,
    };
}

export {setTableFilter, setCampaignCode}