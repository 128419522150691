import React, { Component } from "react";

export default class Pagination extends Component {
  render() {
    return (

      <nav className="paginationcontainer">
        {this.props.pageNumbers.length > 1 &&
          <ul className="pagination">
            {this.props.isPrevBtnActive &&
              <li>
                <a className="-prevpagination" onClick={this.props.btnPrevClick}>Anterior</a>
              </li>
            }

            {this.props.pageNumbers.map(number => {
              if ((number === 1 && this.props.currentPage === 1) ||
                (number >= this.props.currentPage) && (number < (this.props.currentPage + this.props.pageBound))
              ) {
                return (
                  <li key={number} className={this.props.currentPage === number ? 'active' : ''} id={number}>
                    <a id={number} onClick={this.props.handleClick}>{number}</a>
                  </li>
                )
              }
            })}

            {this.props.isNextBtnActive &&
              <li>
                <a className="-nextpagination" onClick={this.props.btnNextClick}>Próximo</a>
              </li>
            }
          </ul>
        }
      </nav>
    );
  }
}
