export const phraseParityClub = () => '0 ponto a cada 0 reais para clientes clube';
export const sessionExpired = () => 'Seu tempo de sessão expirou e o formulário não foi enviado. Recarregue a página e tente novamente.';
export const generalError = () => 'Ocorreu erro. Tente novamente ou entre em contato com o time de Suporte Esfera. suporte@servicosesfera.com.br';
export const collaboratorFieldNameInvalid = () => 'Nome do colaborador inválido';
export const collaboratorFieldEmailInvalid = () => 'Email do colaborador inválido';
export const collaboratorFieldEmailInvalidFormat = () => 'Email do colaborador com formato inválido';
export const collaboratorFieldIdentifierInvalid = () => 'CPF do colaborador inválido';
export const collaboratorSuccessCreated = () => 'Colaborador cadastrado com sucesso!';
export const collaboratorSuccessUpdated = () => 'Colaborador atualizado com sucesso!';
export const collaboratorSuccessDeleted = () => 'Colaborador excluído com sucesso!';
export const termSuccessUpdated = () => 'Termo atualizado com sucesso!';
export const termFieldInvalid = () => 'Termo e condições inválido!';
export const showLpCampaign = () => 'Exibir na lista de campanhas';
          