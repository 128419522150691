import React, { Component } from "react";

import NavBar from "../../components/navbar/NavBar";
import HomeOptions from "../../components/homePage/options/HomeOptions";
import FirstAccessTerms from "../../terms/FirstAccessTerms";

import Footer from "../../components/footer/Footer";


export default class Home extends Component {
  constructor(props) {

    super(props);
    this.state = {
      userAuth: props.state.authenticated && props.state.email,
      site: undefined,
      props: props.state
    }
  }

  handleSite(site) {
    this.setState({
      site: site
    });
  }

  render() {
    return (
      <div>
        {this.state.userAuth ?

          <div>

            {!this.state.site &&
              <>
                <NavBar
                  logout={this.props.logout}
                  keycloak={this.props.state.keycloak}
                  name={this.props.state.name}
                  email={this.props.state.email}
                />
                <div className="admin-menu">
                  <div className="admin-menu-header">Selecione o site</div>
                  <div className="buttons-admin-menu">
                    {((this.props.state.isAdmin || this.props.state.isPartner || this.props.state.isBackoffice)  && !this.props.state.isAdminCashbackFiles) && <button
                      className="button-admin"
                      id="cashback"
                      onClick={() => { this.handleSite('cashback'); }}
                    >
                      Cashback
                    </button>}
                    {(this.props.state.isAdmin || this.props.state.isPartner || this.props.state.isBackoffice) && <button
                      className="button-admin"
                      id="pontos"
                      onClick={() => { this.handleSite('pontos'); }}
                    >
                      Pontos
                    </button>}
                    {(!this.props.state.isAdmin && !this.props.state.isPartner && !this.props.state.isBackoffice && !this.props.state.isBackofficeCashbackFiles && !this.props.state.isAdminCashbackFiles) && <button
                      className="button-admin"
                      id="motorDeIncentivos"
                      onClick={() => { this.handleSite('pontos'); }}
                    >
                      Upload de Arquivos
                    </button>}
                    {(this.props.state.isAdmin || this.props.state.isAdminCashbackFiles || this.props.state.isBackofficeCashbackFiles) && <button
                      className="button-admin"
                      id="motorDeIncentivos"
                      onClick={() => { this.handleSite('motorDeIncentivos'); }}
                    >
                      Motor de Incentivos
                    </button>}
                  </div>
                </div>
                <Footer />
              </>

            }
            {this.state.site &&
              <HomeOptions site={this.state.site} state={this.props.state} logout={this.props.logout}/>
            }
            {this.props.state.modal && this.props.state.tipo == 'firstaccess' ? (
              <FirstAccessTerms
                logout={this.props.logout}
                keycloak={this.props.state.keycloak}
                modal={this.props.state.modal}
                tipo={this.props.state.tipo}
                termContent={this.props.state.termContent}
                authHideModal={this.props.onHideModal}
              />
            ) : null}

          </div>
          :
          <div>Initializing Keycloak...</div>
        }
      </div>
    )

  }
}