const INITIAL_STATE = {tableFilter: "normal", campaignCode: ""}

export default function (state = INITIAL_STATE, action){
    try{
        switch (action.type) {
            case 'ACTUAL_TABLE_FILTER':
                return {...state, tableFilter: action.payload}
            case 'CAMPAIGN_CODE_FILTER':
                return {...state, campaignCode: action.payload}
            default:
                return state
        }
    }catch(e){
        return state
    }
}