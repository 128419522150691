import React, { Component } from "react";

import UploadAdminIncentiveEngine from "../../components/uploadIncentiveEngine/UploadAdminIncentiveEngine";
import TableIncentiveEngine from "../../components/tableIncentiveEngine/TableIncentiveEngine";

export default class ImportIncentiveEngine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled_upload: true,
    };

    this.loadUserCompany = this.loadUserCompany.bind(this);
  }
  async loadUserCompany() {
      return this.props.company;
    }
    render() {
      return (
        <div>
          <div
            className="row wow fadeIn text-center"
            id="upload-and-download-container"
          >
            <UploadAdminIncentiveEngine
              isAdmin={this.props.isAdmin}
              isAdminCashbackFiles={this.props.isAdminCashbackFiles}
              isBackofficeCashbackFiles={this.props.isBackofficeCashbackFiles}
              keycloak={this.props.keycloak}
              logout={this.props.logout}
            />
          </div>

          <TableIncentiveEngine 
            isAdmin={this.props.isAdmin}
            isAdminCashbackFiles={this.props.isAdminCashbackFiles}
            isBackofficeCashbackFiles={this.props.isBackofficeCashbackFiles}
            keycloak={this.props.keycloak} 
            logout={this.props.logout}
          />
        </div>
      );
  }
}
