import React from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCol } from 'mdbreact';
import { DateRange } from 'react-date-range';
import { pt } from 'date-fns/locale';
import Loading from '../loading/Loading';

export default props => {

    if (props.tipo === 'alerta' && props.modal) {
     
        return  <MDBModal className="modal-notify modal-info text-center text-white" isOpen={props.modal} toggle={props.metodoHiden}>
                    <MDBModalHeader><strong>Aviso!</strong></MDBModalHeader>
                    <MDBModalBody>
                        <h3>{props.titulo}</h3>
                        <p>{props.subtitulo}</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={props.metodoHiden}>Fechar</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

    } else if (props.tipo === 'sucesso') {

        return  <MDBModal className="modal-dialog modal-notify modal-success text-center text-white" isOpen={props.modal} toggle={props.metodoHiden} >
                    <MDBModalHeader><strong>Arquivo enviado com Sucesso</strong></MDBModalHeader>
                    <MDBModalBody>
                        <h2>Importação realizada!</h2>
                        <p>Aguarde o processamento do Arquivo!</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={props.metodoHiden}>Fechar</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

    } else if (props.tipo === 'sucessoMDI') {

        return  <MDBModal className="modal-dialog modal-notify modal-success text-center text-white" isOpen={props.modal} toggle={props.metodoHiden} >
                    <MDBModalHeader><strong>Arquivo enviado com Sucesso</strong></MDBModalHeader>
                    <MDBModalBody>
                        <h2>Importação realizada!</h2>
                        <p>Arquivo carregado com sucesso, aguarde o processamento e acompanhe o resultado no arquivo de retorno</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={props.metodoHiden}>Fechar</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

    }
    else if (props.tipo === 'sucessoVLD') {

        return  <MDBModal className="modal-dialog modal-notify modal-success text-center text-white" isOpen={props.modal} toggle={props.metodoHiden} >
                    <MDBModalHeader><strong>Validação</strong></MDBModalHeader>
                    <MDBModalBody>
                        <h2>Validação feita com sucesso</h2>
                        <p>Arquivo está formatado corretamente e pode ser importado</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={props.metodoHiden}>Fechar</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

    }
    else if (props.tipo === 'sucessoDLT') {

        return  <MDBModal className="modal-dialog modal-notify modal-success text-center text-white" isOpen={props.modal} toggle={props.metodoHiden} >
                    <MDBModalHeader><strong>Deletado</strong></MDBModalHeader>
                    <MDBModalBody>
                        <h2>Sucesso</h2>
                        <p>Arquivo apagado com sucesso</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={props.metodoHiden}>Fechar</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

    } else if (props.tipo === 'sucessoPCS') {

        return  <MDBModal className="modal-dialog modal-notify modal-success text-center text-white" isOpen={props.modal} toggle={props.metodoHiden} >
                    <MDBModalHeader><strong>Processado</strong></MDBModalHeader>
                    <MDBModalBody>
                        <h2>Sucesso</h2>
                        <p>Arquivo processado com sucesso</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={props.metodoHiden}>Fechar</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

    } else if (props.tipo === 'error') {

        return  <MDBModal className="modal-dialog  modal-notify modal-danger text-center text-white" isOpen={props.modal} toggle={props.metodoHiden} >
                    <MDBModalHeader><strong>Error!</strong></MDBModalHeader>
                    <MDBModalBody>
                        <h2>{props.titulo}</h2>
                        <p>Caso a mensagem não ajude favor entre em contato com nossos canais de atendimento e informe o codigo de erro abaixo: <br /> Code error - {props.codeError}</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={props.metodoHiden}>Fechar</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

    } else if (props.tipo === 'errorMDI') {

        return  <MDBModal className="modal-dialog  modal-notify modal-danger text-center text-white" isOpen={props.modal} toggle={props.metodoHiden}>
                    <MDBModalHeader><strong>Error!</strong></MDBModalHeader>
                    <MDBModalBody>
                        <h2>{props.titulo}</h2>
                        <p>Falha ao carregar arquivo. Verifique se o layout do arquivo está correto e tente novamente. <br /> {props.codeError && <span>Messagem de erro: {props.codeError}</span>}</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={props.metodoHiden}>Fechar</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

    } else if (props.tipo === 'datapicker') {
     
        return  (
            <MDBModal className="modal-dialog modal-notify modal-info text-center text-white" isOpen={props.modal} toggle={props.metodoHiden}>
                <MDBModalHeader><strong>Selecione um período ou data</strong></MDBModalHeader>
                <MDBModalBody>
                    <MDBCol sm="13">
                        <DateRange
                            editableDateInputs={true}
                            onChange={item => props.setStateProp(item.selection)}
                            moveRangeOnFirstSelection={false}
                            ranges={props.state}
                            locale={pt}
                        />
                    </MDBCol>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="danger" onClick={props.onClearDateFilter}>Limpar filtro</MDBBtn>
                    <MDBBtn color="primary" onClick={props.onHidenApply}>Aplicar</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        )

    } else if (props.tipo === 'firstaccess') {
     
        return  (
            <MDBModal className="modal-dialog modal-notify modal-info text-center text-white" isOpen={props.modal} toggle={() => false} size="lg">
                <MDBModalHeader className="danger-color-dark"><strong>Termos e Condições</strong></MDBModalHeader>
                <MDBModalBody>
                    <div>
                        <div ref={props.termsRef} className="text-justify"></div>
                        <div className="custom-control custom-checkbox text-right">
                            <input type="checkbox" className="custom-control-input" id="termsAndConditions" checked={props.acceptTerms} onChange={props.handleAcceptTerms}></input>
                            <label className="custom-control-label" htmlFor="termsAndConditions">Concordo com os termos e condições</label>
                        </div>
                    </div>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="danger" onClick={props.handleNoAcceptClose} disabled={props.acceptTerms}>Não Concordo</MDBBtn>
                    <MDBBtn color="danger" onClick={props.metodoHiden} disabled={!props.acceptTerms}>Continuar</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        )
    } else if (props.tipo === 'loading') {
     
        return  (
            <MDBModal isOpen={props.modal} toggle={() => false} size="sm" centered>
                <MDBModalBody>
                    <div className="text-center">
                        <Loading />
                    </div>
                </MDBModalBody>
            </MDBModal>
        )
    } else if (props.tipo === 'regulamento') {
        return (
            <MDBModal className="modal-dialog modal-notify modal-info text-center text-white" isOpen={props.modal} toggle={props.metodoHiden} size="lg">
                <MDBModalHeader className="danger-color-dark"><strong>Regulamento</strong></MDBModalHeader>
                <MDBModalBody>
                    <div ref={props.regulationRef} className="text-justify"></div>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="danger" onClick={props.metodoHiden}>Fechar</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        );
    } else if (props.tipo === 'verificacao') {
        return (
            <MDBModal className="modal-dialog modal-notify modal-info text-center text-white" isOpen={props.modal} toggle={props.metodoHiden} size="lg">
                <MDBModalHeader className="danger-color-dark"><strong>Importação não realizada. Revise o arquivo!</strong></MDBModalHeader>
                <MDBModalBody>
                    <div className="text-justify">
                        <h4>Alguns erros foram encontrados no arquivo, sendo listados abaixo:</h4>
                        <ul>
                            {props.validationErrors.map(function(value, index){
                                return (
                                    <li key={index}>
                                        Coluna: {value['column']} , Linha: {value['row']}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="danger" onClick={props.metodoHiden}>Fechar</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        );
    } else if (props.tipo === 'verificacaoCampaignCode') {
        return (
            <MDBModal className="modal-dialog modal-notify modal-info text-center text-white" isOpen={props.modal} toggle={props.metodoHiden} size="lg">
                <MDBModalHeader className="danger-color-dark"><strong>Importação não realizada. Revise o arquivo!</strong></MDBModalHeader>
                <MDBModalBody>
                    <div className="text-justify">
                        <h4>Código da Campanha informada no Arquivo, está diferente do Código da Campanha Selecionada!</h4>
                    </div>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="danger" onClick={props.metodoHiden}>Fechar</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        );
    }
}

