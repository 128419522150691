import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import UserFilter from "./UserFilter"; 
import DateFilter from "./DateFilter";
import ProcessedFilesFilter from "./ProcessedFilesFilter";

import {setTableFilter, setCampaignCode} from './FilterActionss';

class TableFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableFilter: 'normal',
            handleDates: [{
                startDate: new Date(new Date().setHours(0,0,0,0)),
                endDate: new Date(new Date().setHours(0,0,0,0)),
                key: 'selection',
            }],
        };

        this.setTableFilter = this.setTableFilter.bind(this);
        this.userFilter = this.userFilter.bind(this);
        this.handleUserFilter = this.handleUserFilter.bind(this);
        this.handleDateFilter = this.handleDateFilter.bind(this);
        this.dateRangesFilter = this.dateRangesFilter.bind(this);
        this.processedFilesFilter = this.processedFilesFilter.bind(this);
        this.handleProcessedFilesFilter = this.handleProcessedFilesFilter.bind(this);
        this.handleUserAndDateFilter = this.handleUserAndDateFilter.bind(this);
        this.cleanModalDate = this.cleanModalDate.bind(this);
        this.setStateProp = this.setStateProp.bind(this);
    }

    setTableFilter(val) {
        this.props.setTableFilter(val);
    }

    cleanModalDate() {
        this.setState({
            handleDates: [{
                startDate: new Date(new Date().setHours(0,0,0,0)),
                endDate: new Date(new Date().setHours(0,0,0,0)),
                key: 'selection',
            }]
        });
    }

    setStateProp (item) {
        this.setState({
            handleDates: [item]
        });
    }

    userFilter (tableData) {
        return tableData && tableData.sourceUser == this.props.userLogged;
    }

    processedFilesFilter (tableData) {
        if(tableData.campaignCode === this.props.campaignCode) {
            return tableData.statusProcessed !== undefined;
        }
    }

    dateRangesFilter (date) {
        const dateStart = this.state.handleDates[0].startDate.getTime();
        const dateEnd = this.state.handleDates[0].endDate.getTime();
        const importDate = new Date(date.importDate).getTime();
        return importDate >= dateStart && importDate <= dateEnd;
    }

    handleUserFilter () {
        const newTable = this.props.handleDefaultTable();
        return newTable.rows.filter(this.userFilter);
    }

    handleDateFilter () {
        const newTable = this.props.handleDefaultTable();
        return newTable.rows.filter(this.dateRangesFilter);
    }

    handleProcessedFilesFilter () {
        const newTable = this.props.handleDefaultTable();
        return newTable.rows.filter(this.processedFilesFilter);
    }

    handleUserAndDateFilter () {
        return this.handleUserFilter().filter(this.dateRangesFilter);
    }

    render() {
        return (
            <React.Fragment>
                <span>Filtros</span>

                {this.props.campaignCode === "" || this.props.campaignCode === undefined  ?
                (
                    <div className="d-inline">
                        <UserFilter 
                            getDefaultTable={this.props.getDefaultTable}
                            tableFilter={this.props.tableFilter}
                            setTableFilter={this.setTableFilter}
                            setDatatable={this.props.setDatatable}
                            userFilter={this.handleUserFilter}
                            dateFilter={this.handleDateFilter}
                            userAndDateFilter={this.handleUserAndDateFilter}
                            keycloak={this.props.keycloak}
                        />
                        <DateFilter 
                            state={this.state.handleDates}
                            setStateProp={this.setStateProp}
                            tableFilter={this.props.tableFilter}
                            setTableFilter={this.setTableFilter}
                            setDatatable={this.props.setDatatable}
                            cleanModal={this.cleanModalDate}
                            dateFilter={this.handleDateFilter}
                            userAndDateFilter={this.handleUserAndDateFilter}
                            getDefaultTable={this.props.getDefaultTable}
                            userFilter={this.handleUserFilter}
                            keycloak={this.props.keycloak}
                        />
                    </div>
                ):
                (
                    <div className="d-inline">
                        <ProcessedFilesFilter 
                            setDatatable={this.props.setDatatable}
                            processedFilesFilter={this.handleProcessedFilesFilter}
                        />
                    </div>
                )}
                
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({ tableFilter: state.filter.tableFilter, campaignCode: state.filter.campaignCode });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setTableFilter, setCampaignCode }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(TableFilters);