import React, { Component } from "react";
import DetailsItem from "./DetailsItem";

export default class TransactionItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        }

        this.clickExpanded = this.clickExpanded.bind(this);
        this.existItemToCancellation = this.existItemToCancellation.bind(this);
    }

    clickExpanded() {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    existItemToCancellation() {
        var itemsEnabledCancellation = false;

        this.props.transaction.detailItensTransactions.forEach(function (detail) {
            if (detail.cancellationAvailable) {
                itemsEnabledCancellation = true
            }
        });

        return itemsEnabledCancellation;
    }

    render() {
        return (

            <li className="js-selecttransactionitem">
                <div className="-transactionitem">

                    <div className="-iconbox">
                        <img src={this.props.transaction.imgBrand} alt={this.props.transaction.productDescription} />
                    </div>

                    <div className="-contentinfo">
                        <div className="-infoorder">
                            <span className="-paymenttype">{this.props.transaction.transactionTypeDescription}</span>
                            <span className="-paymenttype">{this.props.transaction.productDescription} em {this.props.transaction.numberInstallments}x</span>
                            <span className="-price">{this.props.transaction.sumDetailsCardPaymentAmount}</span>
                        </div>

                        <div className="-date">
                            <div className="-textmain text-uppercase">Data/Horário</div>
                            <span className="-text">{this.props.transaction.transactionDate}</span>
                            <span className="-text">{this.props.transaction.transactionTime}</span>
                        </div>

                        <div className="-partnerinfo">
                            <div className="-textmain text-uppercase">NRO Pedido</div>
                            <span className="-text">{this.props.transaction.orderId}</span>
                        </div>

                        <div className="-ordernumber">
                            <div className="-textmain text-uppercase">Autorização</div>
                            <span className="-text">{this.props.transaction.terminalNsu}</span>
                        </div>

                        <div className="-paymenttype">
                            <div className="-textmain text-uppercase">NRO CV</div>
                            <span className="-text">{this.props.transaction.acquirerTransactionId}</span>
                        </div>

                        <div className="-transactionstatus">
                            <div className="-textmain text-uppercase">Transação</div>
                            <span className="-text">{this.props.transaction.transactionStatusDescription}</span>
                        </div>

                        {this.props.transaction.chargebackAmount &&
                            <div className="-chargeback">
                                <div className="-textmain text-uppercase">Chargeback</div>
                                <span className="-text">{this.props.transaction.chargebackAmount}0</span>
                            </div>
                        }
                        {this.existItemToCancellation() &&
                            this.props.transaction.cancellationAvailable &&
                            this.props.visibleButtonCancellation &&
                            <div className="-cancelledtransaction js-openmodalcancellation"
                                onClick={() => this.props.showModal(this.props.transaction)}>
                                Cancelar
                            </div>
                        }
                    </div>
                    {(this.props.transaction.detailItensTransactions.length) > 0 ?
                        <div className="js-btnactions -actionscontainer" onClick={this.clickExpanded}>
                            <div className="-btnaction">
                                <i className={'icon-arrow-right -iconarrowtransaction' +
                                    (this.state.expanded ? ' -active' : '')
                                }></i>
                            </div>
                        </div>
                        :
                        <div className="js-btnactions -actionscontainer">
                            <div className="-btnaction">
                                <i></i>
                            </div>
                        </div>
                    }
                </div>
                <ul className={'-containerlistdetails' +
                    (this.state.expanded ? ' -active' : '')
                }>
                    {this.props.transaction.detailItensTransactions.map((detail, indexDetails) =>
                        <DetailsItem
                            key={detail.marketplaceTransactionId + indexDetails}
                            detail={detail}
                            showModal={this.props.showModal}
                            orderId={this.props.transaction.orderId}
                            visibleButtonCancellation={this.props.visibleButtonCancellation}
                        />
                    )}
                </ul>
            </li>
        );
    }
}


