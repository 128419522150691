import React, { Component } from "react";

export default class OptionsAction extends Component {
  render() {
    return (
      <div className="optionsactions">
      <div className="-options">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div className="-filterwrapper">
                <div className="-btnwrapper js-btn-wrapper">
                  <button type="button" className="btn -active">Transações</button>

                  <button type="button" className="btn" onClick={this.props.downloadCSV} disabled={this.props.loadingExport}>
                    <img src="../ic-changed.png" alt="" className="-iconbtn" />
                    <span>Exportar</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
