import React, { Component, Fragment } from "react";
import axios from "axios";
import SelectPartners from "../select/SelectPartners";

export default class ListSubPartners extends Component {
  constructor(props) {
    super(props);

    this.handleSelectPartner = this.handleSelectPartner.bind(this);

    this.state = {
      subPartners: [],
      loadingSubPartners: true,
      showListSubParceiros: false,
      loadingMessage: "",
    };
  }

  componentDidMount() {
    const BASE_URL = `${window.REACT_APP_URL}`;

    axios
      .get(`${BASE_URL}/partner/simple`)
      .then((response) => {
        var partnersData = response.data.results;

        partnersData.sort(function (a, b) {
          if (a.partnerName < b.partnerName) {
            return -1;
          }
          if (a.partnerName > b.partnerName) {
            return 1;
          }
          return 0;
        });

        if (response.data.results) {
          !this.isCancelled &&
            this.setState({
              partners: partnersData,
              loadingPartners: false,
              loadingMessage: "",
            });
        } else {
          !this.isCancelled &&
            this.setState({
              partners: [],
              loadingPartners: false,
              loadingMessage: "Não há parceiros disponíveis",
            });
        }
      })
      .catch((error) => {
        if (error.response) {
          const httpStatusError = JSON.stringify(error.response.status);
          if (httpStatusError === "401") {
            this.props.logout();
          }
        }
      });
  }

  componentWillUnmount() {
    this.isCancelled = true;
  }

  handleSelectPartner(partnerId) {

    this.setState({ 
      partnerSelected: partnerId, 
      subPartners: [],
      showListSubParceiros: true,
      loadingSubPartners: true,
      loadingMessage: "Carregando subparceiros...",
    });

    this.getSubPartners(partnerId)

  }

  getSubPartners(partnerId) {
    const BASE_URL = `${window.REACT_APP_URL}`;

    axios
      .get(`${BASE_URL}/partner/locations?partnerId=${partnerId}`)
      .then((response) => {
        var partnersData = response.data.results.subPartners;

        if (!partnersData || partnersData.length === 0 ) {
          !this.isCancelled &&
          this.setState({
            subPartners: [],
            loadingSubPartners: false,
            loadingMessage: "Não há subparceiros cadastrados",
            });
          return;
        }

        partnersData.sort(function (a, b) {
          if (a.partnerName < b.partnerName) {
            return -1;
          }
          if (a.partnerName > b.partnerName) {
            return 1;
          }
          return 0;
        });

        !this.isCancelled &&
          this.setState({
            subPartners: partnersData,
            loadingSubPartners: false,
            loadingMessage: "",
          });
      })
      .catch((error) => {
        if (error.response) {
          const httpStatusError = JSON.stringify(error.response.status);
          if (httpStatusError === "401") {
            this.props.logout();
          }
        }
      });
  }

  render() { 
    return (
      <div id="page-wrapper">
        <div id="list-container">

            <Fragment>

              <SelectPartners 
                handleSelectPartner={partnerId => this.handleSelectPartner(partnerId)}
              />

              {this.state.showListSubParceiros === true ? (
                !this.state.loadingSubPartners && this.state.subPartners?.length > 0 ? (
                  <table className="table-list" id="table-partners">
                    <thead>
                      <tr>
                        <th>Código</th>
                        <th>Nome do subparceiro</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.subPartners.map(({subPartnerCode, subPartnerName }, index) => {
                        return (
                          <tr key={index}>
                            <td>{subPartnerCode}</td>
                            <td>{subPartnerName}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className="loading-msg">{this.state.loadingMessage}</div>
                )
                
              ) :  null }

            </Fragment>
        </div>
      </div>
    );
  }
}
