export const messageError = () => "Ops, ocorreu um erro ao retornar as transações buscadas.";

export const messageNoTransaction = () => "Não foram encontradas transações para o período selecionado.";

export const messageDate = () => "Data inválida.";

export const messageExport = () => "Exportando arquivo csv...";

export const messageSuccessExport = () => "Exportação concluída.";

export const messageErrorExport = () => "Erro na exportação.";

export const messageErrorLimitDaysFilter = () => "Não é permitido consultas de um período maior que 30 dias.";

export const messageCancellationSuccess = () => "Cancelamento realizado.";

export const messageCancellationError = () => "Houve um erro inesperado. Tente novamente mais tarde.";

export const messageCancellationExists = () => "Um ou mais itens do pedido já estão em processo de cancelamento. Verifique novamente os itens enviados.";



