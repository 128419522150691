import React, { Component } from 'react';
import axios from 'axios';
import { stateToHTML } from 'draft-js-export-html';

import {
  phraseParityClub, 
  sessionExpired, 
  generalError,
  showLpCampaign
} from '../../../helpers/GlobalMessages'

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import setEditorValidated from '../../textEditor/RichEditorActions';
import { comparatorDates } from '../../../helpers/GlobalFunctions'

import Editor from '../../textEditor/RichEditorPopulated';
import iconInfo from '../../../assets/img/icon-info.svg';
import ContainerCategories from '../categories/ContainerCategories';
import Helpers from '../categories/utils/Helpers';

const initialState = {
  partner_name: '',
  partner_code: '',
  subPartner_name: '',
  subPartner_code: '',
  monthsToPointsExpire: '',
  campaign_name: '',
  campaign_code: '',
  esferaParity: '0',
  partnerParity: '0',
  sumPoints: '0',
  adjustPartnerPoints: '',
  generalParity: '',
  clubParity: '',
  flagClubParity: '',
  display: '',
  typePoints: '',
  start_date: '',
  deadline: '',
  deadlineCreditPoints: '',
  creditPhrasePoints: '',
  urlImageOCC: '',
  urlPagePartner: '',
  uploadFileCampaignExpired: '',

  start_date_br_format: '',
  deadline_br_format: '',

  disabled_button: false,
  disabled_button_delete: false,
  disabled_select: true,
  loading_message: 'Carregando parceiros...',

  start_date_error: '',
  deadline_error: '',
  credits_points_error: '',
  campaign_name_error: '',
  esferaParity_error: '',
  partnerParity_error: '',
  parity_general_code_error: '',
  parity_club_code_error: '',
  club_client_percent_error: '',
  generalClientPercent_error: '',
  clubClientParity_error: '',
  typePoints_error: '',
  text_editor_content_html: '',
  text_editor_content_error: '',
  campaignRuleUrl: '',
  clubClientPercent: '0',
  generalClientPercent: '0',
  clubClientParity: '0',
  accumulationLimit: null,
  deadlineCreditPoints_error: '',
  creditPhrasePoints_error: '',
  urlPagePartner_error: '',
  campaign_rule_id: '',
  monthsToPointsExpire_error: '',
};
const BASE_URL = `${window.REACT_APP_URL}`;

const config = {
  headers: {
    'content-type': 'application/json',
  },
};

class FormCampaignEdit extends Component {
  state = initialState;

  componentWillMount() {
    const replacedCampaignName = this.props.campaign.campaignName.replace(/[\\"]/g, '');
    const replacedCampaignCode = this.props.campaign.campaignCode.replace('.', '');

    axios
      .get(
        `${BASE_URL}/rule/${replacedCampaignCode}?campaignName=${replacedCampaignName}`
      )
      .then((response) => {
        const isResultsFound = response && response.data && response.data.results;
        const contentHtml = isResultsFound && `${response.data.results.campaignRule}` || '';
        const ruleId = isResultsFound && `${response.data.results.id}` || '';
        const ruleUrl = isResultsFound && response.data.results.campaignRuleUrl || '';

        this.setState({
          text_editor_content_html: contentHtml,
          campaignRuleUrl: ruleUrl,
          campaign_rule_id: ruleId,
        });
      })
      .catch((error) => {
        if (error.response) {
          const httpStatusError = JSON.stringify(error.response.status);
          if (httpStatusError !== '200') {
            this.props.handleSendFormCampaignFail(
              'Ocorreu um erro ao carregar o regulamento.'
            );
          }
        }
      });
  }

  formatDateHours = (completDate) => {
    var data = String(completDate).split('.');
    return data[0];
  }

  componentDidMount() {
    if (!this.props.campaign.adjustPartnerPoints && this.props.campaign.clubParity) {
      this.setState({
        partner_name: this.props.campaign.partnerName,
        partner_code: this.props.campaign.partnerCode,
        subPartner_name: this.props.campaign?.subPartner?.subPartnerName || null,
        subPartner_code: this.props.campaign?.subPartner?.subPartnerCode || null,
        monthsToPointsExpire: this.props.campaign.monthsToPointsExpire,
        campaign_name: this.props.campaign.campaignName,
        campaign_code: this.props.campaign.campaignCode,
        esferaParity: this.props.campaign.esferaParity,
        partnerParity: this.props.campaign.partnerParity,
        adjustPartnerPoints: false,
        display: this.props.campaign.display,
        generalParity: this.props.campaign.generalParity,
        flagClubParity: true,
        typePoints: this.props.campaign.typePoints,
        clubParity: this.props.campaign.clubParity,
        sumPoints: Helpers.sumParities(this.props.campaign.esferaParity, this.props.campaign.partnerParity),
        clubClientPercent: this.props.campaign.clubClientPercent,
        generalClientPercent: this.props.campaign.generalClientPercent,
        clubClientParity: this.props.campaign.clubClientParity,
        accumulationLimit: this.props.campaign.accumulationLimit,
        start_date: this.formatDateHours(this.props.campaign.startDate),
        start_date_br_format: this.props.campaign.startDateBrlFormat,
        deadlineCreditPoints: this.props.campaign.deadlineCreditPoints,
        creditPhrasePoints: this.props.campaign.creditPhrasePoints,
        urlImageOCC: this.props.campaign.urlImageOCC,
        urlPagePartner: this.props.campaign.urlPagePartner,
        uploadFileCampaignExpired: this.props.campaign.uploadFileCampaignExpired,
      });
      if (this.props.campaign.deadline) {
        this.setState({
          deadline: this.formatDateHours(this.props.campaign.deadline),
          deadline_br_format: this.props.campaign.deadlineBrlFormat,
        });
      }
    } else {
      this.setState({
        partner_name: this.props.campaign.partnerName,
        partner_code: this.props.campaign.partnerCode,
        subPartner_name: this.props.campaign?.subPartner?.subPartnerName || null,
        subPartner_code: this.props.campaign?.subPartner?.subPartnerCode || null,
        monthsToPointsExpire: this.props.campaign.monthsToPointsExpire,
        campaign_name: this.props.campaign.campaignName,
        campaign_code: this.props.campaign.campaignCode,
        esferaParity: this.props.campaign.esferaParity,
        partnerParity: this.props.campaign.partnerParity,
        adjustPartnerPoints: this.props.campaign.adjustPartnerPoints,
        display: this.props.campaign.display,
        generalParity: this.props.campaign.generalParity,
        flagClubParity: this.props.campaign.flagClubParity,
        typePoints: this.props.campaign.typePoints,
        clubParity: this.props.campaign.clubParity,
        sumPoints: Helpers.sumParities(this.props.campaign.esferaParity, this.props.campaign.partnerParity),
        clubClientPercent: this.props.campaign.clubClientPercent,
        generalClientPercent: this.props.campaign.generalClientPercent,
        clubClientParity: this.props.campaign.clubClientParity,
        accumulationLimit: this.props.campaign.accumulationLimit,
        start_date: this.formatDateHours(this.props.campaign.startDate),
        start_date_br_format: this.props.campaign.startDateBrlFormat,
        deadlineCreditPoints: this.props.campaign.deadlineCreditPoints,
        creditPhrasePoints: this.props.campaign.creditPhrasePoints,
        urlImageOCC: this.props.campaign.urlImageOCC,
        urlPagePartner: this.props.campaign.urlPagePartner,
        uploadFileCampaignExpired: this.props.campaign.uploadFileCampaignExpired,
      });
      if (this.props.campaign.deadline) {
        this.setState({
          deadline: this.formatDateHours(this.props.campaign.deadline),
          deadline_br_format: this.props.campaign.deadlineBrlFormat,
        });
      }
    }
  }

  validade = () => {
    let campaign_name_error = '';
    let typePoints_error = '';
    let esferaParity_error = '';
    let partnerParity_error = '';
    let parity_general_code_error = '';
    let parity_club_code_error = '';
    let club_client_percent_error = '';
    let generalClientPercent_error = '';
    let clubClientParity_error = '';
    let start_date_error = '';
    let deadline_error = '';
    let deadlineCreditPoints_error = '';
    let creditPhrasePoints_error = '';
    let urlPagePartner_error = '';
    let text_editor_content_error = '';
    let monthsToPointsExpire_error = '';

    if (this.state.campaign_name === '') {
      campaign_name_error = 'Nome da campanha inválido';
    }

    if (!this.state.typePoints || this.state.typePoints === 'SELECT') {
      typePoints_error = 'Tipo de ponto inválido';
    }

    if (this.state.esferaParity === '') {
      esferaParity_error = 'Código de paridade esfera inválido';
    }

    if (this.state.partnerParity === '') {
      partnerParity_error = 'Código de paridade parceiro inválido';
    }

    if (!this.state.generalParity) {
      parity_general_code_error = 'Código de paridade geral inválido';
    }

    if(this.state.flagClubParity) {
      if (!this.state.clubParity) {
        parity_club_code_error = 'Código de paridade de clube inválido';
      }
    }

    if ((!this.state.clubClientPercent && this.state.clubClientPercent !== 0) || this.state.clubClientPercent > 100) {
        club_client_percent_error = 'É permitido somente número de 0 a 100';
    }

    if (this.state.generalClientPercent === undefined || this.state.generalClientPercent === null) {
      this.setState({ generalClientPercent: '0' });
    } else {
      if (this.state.generalClientPercent.length === 0 || this.state.generalClientPercent > 100) {
        generalClientPercent_error = 'É permitido somente número de 0 a 100';
      }
    }

    if (this.state.clubClientParity === undefined || this.state.clubClientParity === null) {
      this.setState({ clubClientParity: '0' });
    } else {
      if (this.state.clubClientParity.length === 0) {
        clubClientParity_error = 'É permitido somente número de 0 a 99';
      }
    }

    if (!this.state.start_date) {
      start_date_error = 'Data de início inválida';
    }

    if (!this.state.deadline) {
      deadline_error = 'Data final inválida';
    }

    if (this.state.deadlineCreditPoints === '' || this.state.deadlineCreditPoints === ' ') {
      deadlineCreditPoints_error = 'Prazo para crédito dos pontos inválido';
    }

    if (!this.state.creditPhrasePoints) {
      creditPhrasePoints_error = 'Frase para créditos dos pontos inválida';
    }

    if(!this.state.urlPagePartner) {
      urlPagePartner_error = 'URL da pagina do parceiro inválida';
    }

    if (this.props.editorValidated === false) {
      text_editor_content_error = 'Regulamento inválido';
    }

    if (this.state.deadline !== '') {
      const result = comparatorDates(this.state.start_date, this.state.deadline);
      if (result) {
        deadline_error = 'Data final deve ser após a data inicial';
      }
    }

    if (this.state.monthsToPointsExpire === '' || this.state.monthsToPointsExpire === ' ') {
      monthsToPointsExpire_error = 'Número de meses inválido';
    }

    if (
      campaign_name_error || 
      typePoints_error || 
      esferaParity_error || 
      partnerParity_error || 
      parity_general_code_error || 
      parity_club_code_error || 
      club_client_percent_error ||
      generalClientPercent_error ||
      clubClientParity_error ||
      start_date_error || 
      deadline_error || 
      deadlineCreditPoints_error || 
      creditPhrasePoints_error ||
      urlPagePartner_error || 
      text_editor_content_error ||
      monthsToPointsExpire_error
      ) {
      this.setState({
        campaign_name_error,
        typePoints_error,
        esferaParity_error,
        partnerParity_error,
        parity_general_code_error,
        parity_club_code_error,
        club_client_percent_error,
        generalClientPercent_error,
        clubClientParity_error,
        start_date_error,
        deadline_error,
        deadlineCreditPoints_error,
        creditPhrasePoints_error,
        urlPagePartner_error,
        text_editor_content_error,
        monthsToPointsExpire_error,
      });
      return false;
    }
    return true;
  };
  
  handleTextInputChange = (event) => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
      [event.target.name + '_error']: ''
    });
  };

  handleSelectTypePoints = (event) => {
    event.preventDefault();
    this.setState({
      typePoints: event.target.value,
      typePoints_error: '',
      successMsg: '',
    });
  };

  handleNumberInputChange = (event) => {
    event.preventDefault();
    var er = /[^0-9.]/;
    er.lastIndex = 0;
    
    if(!er.test(event.target.value)) {
      this.setState({
        [event.target.name]: event.target.value,
        [event.target.name + '_error']: '',
        successMsg: ''
      });
      this.setState((state, _) => {
        return {
          sumPoints: Helpers.sumParities(state.esferaParity, state.partnerParity)
        }
      })
    } else {
        event.target.value = '';
    }
  };

  handleNumberInputChangeCustom = (event) => {
    event.preventDefault();

    const valueMask = Helpers.maskParity(event.target.value)
    this.setState({
      [event.target.name]: valueMask,
      [event.target.name + '_error']: '',
      successMsg: ''
    });
    this.setState((state, _) => ({
        sumPoints:  Helpers.sumParities(state.esferaParity, state.partnerParity)
    }))
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const campaignId = this.props.campaign.id;
    const isValid = this.validade();
    const subPartner = this.props.campaign?.subPartner;
    let subPartners = null;

    if (subPartner) {
      subPartners = {
          subPartnerName: this.state.subPartner_name,
          subPartnerCode: this.state.subPartner_code,
      };
  }
    
    if (isValid) {
      if (this.state.disabled_button) {
        return;
      }

      this.setState({
        disabled_button: true,
      });

      let campaign_rule = '';

      if (this.state.text_editor_content_html != '') {
        campaign_rule = {
          campaignCode: this.state.campaign_code,
          campaignName: this.state.campaign_name,
          campaignRule: this.state.text_editor_content_html,
          campaignRuleUrl: this.state.campaignRuleUrl
        };
      } else {
        campaign_rule = {
          campaignCode: this.state.campaign_code,
          campaignName: this.state.campaign_name,
          campaignRule: '',
          campaignRuleUrl: this.state.campaignRuleUrl
        };
      }

      let campaign = {
        partnerName: this.state.partner_name,
        partnerCode: this.state.partner_code,
        subPartner: subPartners,
        monthsToPointsExpire: this.state.monthsToPointsExpire,
        campaignName: this.state.campaign_name,
        campaignCode: this.state.campaign_code,
        esferaParity: this.state.esferaParity,
        partnerParity: this.state.partnerParity,
        adjustPartnerPoints: this.state.adjustPartnerPoints,
        display: this.state.display,
        generalParity: this.state.generalParity,
        flagClubParity: this.state.flagClubParity,
        clubClientPercent: this.state.clubClientPercent,
        generalClientPercent: this.state.generalClientPercent,
        clubClientParity: this.state.clubClientParity,
        accumulationLimit: this.state.accumulationLimit !== '' ? this.state.accumulationLimit : null,
        typePoints: this.state.typePoints,
        startDate: this.state.start_date,
        deadline: this.state.deadline,
        deadlineCreditPoints: this.state.deadlineCreditPoints,
        creditPhrasePoints: this.state.creditPhrasePoints,
        urlImageOCC: this.state.urlImageOCC,
        urlPagePartner: this.state.urlPagePartner,
        uploadFileCampaignExpired: this.state.uploadFileCampaignExpired,
      };
      if(this.state.flagClubParity) {
        campaign.clubParity = this.state.clubParity;
      }

      if(this.props.partnerAcceptCategory) {
        campaign.categories = Helpers.sanitizeCategories(this.props.categories)
      }

      axios
        .put(`${BASE_URL}/campaign/${campaignId}`, campaign, config)
        .then((response) => {
          if (this.state.campaign_rule_id !== '') {
            this.updateCampaignRule(campaign_rule);
          } else {
            this.createCampaignRule(campaign_rule);
          }
          this.setState(initialState);
          document.getElementById('form-container').reset();
          this.props.handleSendFormCampaignSuccess(
            'Campanha atualizada com sucesso!'
          );
        })
        .catch((error) => {
          this.setState(initialState);
          document.getElementById('form-container').reset();

          if (error.response) {
            const httpStatusError = JSON.stringify(error.response.status);
            if (httpStatusError === '401') {
              this.props.handleSendFormCampaignFail(sessionExpired());
              return;
            } else {
              const message = error.response?.data?.results?.userMessage;
              if (message) {
                this.props.handleSendFormCampaignFail(message);
                return;
              }
            }
          }
          this.props.handleSendFormCampaignFail(generalError());
        });
    }
  };

  updateCampaignRule = (campaign_rule) => {
    axios
      .put(
        `${BASE_URL}/rule/${this.state.campaign_rule_id}`,
        campaign_rule,
        config
      )
      .catch((error) => {
        this.setState(initialState);
        document.getElementById('form-container').reset();

        if (error.response) {
          const httpStatusError = JSON.stringify(error.response.status);
          if (httpStatusError === '401') {
            this.props.handleSendFormCampaignFail(sessionExpired());
            return;
          } else {
            const message = error.response?.data?.results?.userMessage;
            if (message) {
              this.props.handleSendFormCampaignFail(message);
              return;
            }
          }
        }
        this.props.handleSendFormCampaignFail(generalError());
      });
  };

  createCampaignRule = (campaign_rule) => {
    axios
      .post(
      `${BASE_URL}/rule`,
      campaign_rule,
      config
    )
    .catch((error) => {
      this.setState(initialState);
      document.getElementById('form-container').reset();
      if (error.response) {
        const httpStatusError = JSON.stringify(error.response.status);
        if (httpStatusError === '401') {
          this.props.handleSendFormCampaignFail(sessionExpired());
          return;
        } else {
          const message = error.response?.data?.results?.userMessage;
          if (message) {
            this.props.handleSendFormCampaignFail(message);
            return;
          }
        }
      }
      this.props.handleSendFormCampaignFail(generalError());
    });
  };

  handleDelete = (event) => {
    event.preventDefault();

    var result = window.confirm(
      'Você deseja mesmo excluir a campanha de código '.concat(
        this.state.campaign_code,
        '?'
      )
    );

    if (result) {
      if (this.state.disabled_button_delete) {
        return;
      }

      this.setState({
        disabled_button_delete: true,
      });

      axios
        .delete(
          `${BASE_URL}/campaign/${this.state.partner_code}/${this.state.campaign_code}`,
          config
        )
        .then((response) => {
          this.setState(initialState);
          document.getElementById('form-container').reset();
          this.props.handleSendFormCampaignSuccess(
            'Campanha excluída com sucesso!'
          );
        })
        .catch((error) => {
          this.setState(initialState);
          document.getElementById('form-container').reset();

          if (error.response) {
            const httpStatusError = JSON.stringify(error.response.status);
            if (httpStatusError === '401') {
              this.props.handleSendFormCampaignFail(sessionExpired());
              return;
            } else {
              const message = error.response?.data?.results?.userMessage;
              if (message) {
                this.props.handleSendFormCampaignFail(message);
                return;
              }
            }
          }
          this.props.handleSendFormCampaignFail(generalError());
        });
    }
  };

  cleanStartDateInput = (event) => {
    event.preventDefault();
    this.setState({
      start_date: '',
      start_date_br_format: '',
    });
    document.getElementById('start_date').value = '';
  };

  cleanDeadlineInput = (event) => {
    event.preventDefault();
    this.setState({
      deadline: '',
      deadline_br_format: '',
    });
    document.getElementById('deadline').value = '';
  };

  handleStartDateInputChange = (event) => {
    event.preventDefault();
    this.setState({
      start_date: event.target.value,
      start_date_br_format: this.convertHtmlDateToBrlFormat(event.target.value),
      start_date_error: '',
    });
  };

  handleDeadlineInputChange = (event) => {
    event.preventDefault();
    this.setState({
      deadline: event.target.value,
      deadline_br_format: this.convertHtmlDateToBrlFormat(event.target.value),
      deadline_error: '',
    });
  };

  convertBrlDateToHtmlFormat(brlDate) {
    if (brlDate.replace('/', '').length > 0) {
      const day = brlDate.slice(0, 2);
      const month = brlDate.slice(3, 5);
      const year = brlDate.slice(6, 10);
      return year.concat('-', month, '-', day);
    }
  }

  convertHtmlDateToBrlFormat(htmlDate) {
    const day = htmlDate.slice(8, 10);
    const month = htmlDate.slice(5, 7);
    const year = htmlDate.slice(0, 4);
    return day.concat('/', month, '/', year);
  }

  handleTextEditorChange = (text_editor_content_state) => {
    this.setState({
      text_editor_content_html: stateToHTML(
        text_editor_content_state.getCurrentContent()
      ),
    });
  };

  handleChangeCheck = (event) => {
    this.setState({
      [event.target.name]: !this.state[event.target.name],
    });
  };

  handleKeyPressStartDate = (event) => {
    if(event.key === "Backspace") {
      this.setState({
        start_date: '',
      });
      document.getElementById('start_date').value = '';
    }
  };

  handleKeyPressDeadline = (event) => {
    if(event.key === "Backspace") {
      this.setState({
        deadline: '',
      });
      document.getElementById('deadline').value = '';
    }
  };

  standardPhraseParityClub = (data) => {
    if(!data) {
      this.setState({
        clubParity: phraseParityClub(),
      })
    }
    return this.state.clubParity;
  };

  handleNumberPermition = (event) => {
    event.preventDefault();
    const regexNumberPermition = /^[1-9][0-9]{0,2}$/;

    if (event.target.value === '' || regexNumberPermition.test(event.target.value)) {
        const numericValue = parseInt(event.target.value, 10);
        if (event.target.value === '' || (numericValue >= 1 && numericValue <= 999)) {
            this.setState({
                [event.target.name]: event.target.value,
                [event.target.name + '_error']: '',
                successMsg: ''
            });
        }
    }
  };

  render() {
    return (
      <div id='page-wrapper'>
        <form
          id='form-container'
          className='container'
          onSubmit={this.handleSubmit.bind(this)}
        >
          <div className='input-block'>
            <label htmlFor='partner_name'>Nome do Parceiro</label>
            <input
              className='hidden'
              type='text'
              id='partner_code'
              name='partner_code'
              value={this.state.partner_name}
              readOnly={true}
            />
          </div>

          <div className='input-block'>
            <label htmlFor='partner_code'>Código do Parceiro</label>
            <input
              className='hidden'
              type='text'
              id='partner_code'
              name='partner_code'
              value={this.state.partner_code}
              readOnly={true}
            />
          </div>

          <div>
            <div className='input-block'>
              <label htmlFor='subPartner_name'>Nome do Subparceiro</label>
              <input
                className='hidden'
                type='text'
                id='subPartner_code'
                name='subPartner_code'
                value={this.props.campaign ? this.state.subPartner_name : ""}
                readOnly={true}
              />
            </div>

            <div className='input-block'>
              <label htmlFor='subPartner_code'>Código do Subparceiro</label>
              <input
                className='hidden'
                type='text'
                id='subPartner_code'
                name='subPartner_code'
                value={this.props.campaign ? this.state.subPartner_code : ""}
                readOnly={true}
              />
            </div>
          </div>

          <div className='input-block'>
            <label htmlFor='campaign_name'>Nome da Campanha</label>
            <span> (até 40 caracteres)</span>
            <input
              type='text'
              id='campaign_name'
              name='campaign_name'
              value={this.state.campaign_name}
              onChange={this.handleTextInputChange}
              maxLength='40'
            />
          </div>
          <div className='error-msg'>{this.state.campaign_name_error}</div>

          <div className='input-block'>
            <label htmlFor='campaign_code'>Código da Campanha</label>
            <span> (até 10 caracteres)</span>
            <input
              className='hidden'
              readOnly={true}
              type='text'
              id='campaign_code'
              name='campaign_code'
              value={this.state.campaign_code}
            />
          </div>
          <div className='error-msg'>{this.state.campaign_code_error}</div>

          <label htmlFor="typePoints">Tipo de ponto</label>
          <select value={this.state.typePoints} onChange={this.handleSelectTypePoints}>
            <option value="SELECT">Selecione</option>
            <option value="BONUS">Bônus</option>
            <option value="BASE">Base</option>
            <option value="AMEX">Amex</option>
            <option value="CLUB">Club</option>
            <option value="INCT">Incentivos</option>
          </select>
          <div className='error-msg'>{this.state.typePoints_error}</div>

          <div>
            <div className='input-block'>
              <label htmlFor='monthsToPointsExpire'>Duração dos pontos em Meses</label>
              <span className='span-color'> (até 3 caracteres)</span>
              <div class="popover__wrapper">
                <img src={iconInfo} alt="" />
                <div class="popover__content" id='popover_subpartner'>
                  <p class="popover__message">Meses a adicionar a data de criação dos pontos para definir data de expiração.</p>
                </div>
              </div>
              <input
                type='text'
                id='monthsToPointsExpire'
                name='monthsToPointsExpire'
                onChange={this.handleNumberPermition}
                value={this.state.monthsToPointsExpire}
                maxLength='3'
                pattern='\d*'
              />
              <div className='error-msg'>{this.state.monthsToPointsExpire_error}</div>
            </div>
          </div>

          {
            this.props.partnerAcceptCategory &&
            <ContainerCategories 
              originalCategoriesList={this.props.campaign.categories}
              partnerId={this.props.partnerId}
            />
          }
          
          <div className="div-parity">
            <div className='input-block input-block-inside-div'>
              <label htmlFor='esferaParity'>Paridade Esfera</label>
              <span> (até 4 caracteres) </span>
              <div class="popover__wrapper">
                <img src={iconInfo} alt="" />
                <div class="popover__content">
                  <p class="popover__message">Pontos por Real que o cliente vai receber, pagos pela Esfera.</p>
                </div>
              </div>
              <input
                type='text'
                id='esferaParity'
                name='esferaParity'
                onChange={this.handleNumberInputChangeCustom}
                value={this.state.esferaParity}
                maxLength='5'
              />
            </div>
            <div className='error-msg'></div>

            <div className="div-plus">
              <span className="plus">+</span>
            </div>

            <div className='input-block'>
              <label htmlFor='partnerParity'>Paridade Parceiro</label>
              <span> (até 4 caracteres) </span>
              <div class="popover__wrapper">
                <img src={iconInfo} alt="" />
                <div class="popover__content">
                  <p class="popover__message">Pontos por Real que o cliente vai receber, pagos pelo parceiro.</p>
                </div>
              </div>
              <input
                type='text'
                id='partnerParity'
                name='partnerParity'
                onChange={this.handleNumberInputChangeCustom}
                value={this.state.partnerParity}
                maxLength='5'
              />
            </div>
            <div className='error-msg'></div>
          </div>

          {(this.state.esferaParity_error || this.state.partnerParity_error) ? (
            <div className="div-parity-error">
              <div className='input-block input-block-inside-div-error'>
                <div className='error-msg-parity'>{this.state.esferaParity_error}</div>
              </div>

              <div className="div-plus">
                <span className="plus"></span>
              </div>

              <div className='input-block'>
                <div className='error-msg-parity'>{this.state.partnerParity_error}</div>
              </div>
            </div>        
            ) : null
          }

          <span className="sum-points">= &nbsp; <strong>{this.state.sumPoints} pts</strong> &nbsp; a cada R$ 1 Real em compras</span>

          <div className='switch__container-points'>              
            <input
              name="adjustPartnerPoints"
              type='checkbox'
              className='switch switch--shadow'
              id='adjustPartnerPoints'
              onChange={this.handleChangeCheck.bind()} 
              checked={this.state.adjustPartnerPoints}
            />
            <label htmlFor='adjustPartnerPoints'></label>
            <div className="div-switch">
              <span className="span-title-switch">Ajuste de Pontos</span>
              <span className="span-p-switch">O parceiro enviará a relação de compras com paridade de 1 ponto a cada real. O sistema fará o ajuste automático da paridade.</span>
            </div>
          </div>
          
          <div className='switch__container-campaign'>
            <input id='display' className='switch switch--shadow' type="checkbox" name="display" onChange={this.handleChangeCheck.bind()} checked={this.state.display} />
            <label htmlFor="display"></label>
            <div className="div-switch">
              <span className="span-title-switch">{showLpCampaign()}</span>
              <span className="span-p-switch">Esta campanha ficará visível na página de histórico de campanhas.</span>
            </div>
          </div>

          <div className='input-block'>
            <label htmlFor='generalClientPercent'>Porcentagem para Clientes em Geral</label>
            <span> (até 3 caracteres)</span>
            <input
              type='text'
              id='generalClientPercent'
              name='generalClientPercent'
              onChange={this.handleNumberInputChange}
              value={this.state.generalClientPercent}
              maxLength='3'
            />
          </div>
          <div className='error-msg'>{this.state.generalClientPercent_error}</div>

          <div className='input-block'>
            <label htmlFor='generalParity'>Paridade para Clientes em Geral</label>
            <span> (até 50 caracteres)</span>
            <input
              type='text'
              id='generalParity'
              name='generalParity'
              onChange={this.handleTextInputChange}
              value={this.state.generalParity}
              maxLength='50'
            />
          </div>
          <div className='error-msg'>{this.state.parity_general_code_error}</div>

          <div>
            <input type="checkbox" name="flagClubParity" onChange={this.handleChangeCheck.bind()} checked={this.state.flagClubParity} />
            <label htmlFor="flagClubParity" className='checkBox-space' >
              Exibir a Frase de Paridade Clube na LP de Campanhas?
            </label>
            {this.state.flagClubParity ? (<span className='span-color'> (até 50 caracteres)</span>) : null}
          </div>
          {
            this.state.flagClubParity ? (
              <div>
                <div className='input-block'>
                  <input
                    type='text'
                    id='clubParity'
                    name='clubParity'
                    onChange={this.handleTextInputChange}
                    value={!this.state.clubParity ? (this.standardPhraseParityClub(this.state.clubParity)) : (this.state.clubParity)}
                    maxLength='50'
                  />
                </div>
                <div className='error-msg'>{this.state.parity_club_code_error}</div>
              </div>
            ) : null
          }

          <div className='input-block'>
            <label htmlFor='clubClientParity'>Paridade para Cliente Clube</label>
            <span> (até 2 caracteres)</span>
            <input
              type='text'
              id='clubClientParity'
              name='clubClientParity'
              onChange={this.handleNumberInputChange}
              value={this.state.clubClientParity}
              maxLength='2'
            />
          </div>
          <div className='error-msg'>{this.state.clubClientParity_error}</div>

          <div className='input-block'>
            <label htmlFor='clubClientPercent'>Porcentagem para Clientes Clube</label>
            <span> (até 3 caracteres)</span>
            <input
              type='text'
              id='clubClientPercent'
              name='clubClientPercent'
              onChange={this.handleNumberInputChange}
              value={this.state.clubClientPercent}
              maxLength='3'
            />
          </div>
          <div className='error-msg'>{this.state.club_client_percent_error}</div>

          <div className='input-block'>
            <label htmlFor='accumulationLimit'>Limite máximo de acúmulo de pontos nesta campanha</label>
            <input
              type='text'
              id='accumulationLimit'
              name='accumulationLimit'
              onChange={this.handleNumberInputChange}
              value={this.state.accumulationLimit}
            />
          </div>

          <div className='input-block'>
            <label htmlFor='start_date'>Data de Início</label>
            <div className='input-date-area'>
              <input
                type='datetime-local'
                id='start_date'
                name='start_date'
                onKeyDown={this.handleKeyPressStartDate}
                onChange={this.handleStartDateInputChange}
                value={this.state.start_date}
              />
              <button
                id='clean-start-date-input'
                onClick={this.cleanStartDateInput}
              >
                Limpar
              </button>
            </div>
          </div>
          <div className='error-msg'>{this.state.start_date_error}</div>

          <div className='input-block'>
            <label htmlFor='deadline'>Data de Fim</label>
            <div className='input-date-area'>
              <input
                type='datetime-local'
                id='deadline'
                name='deadline'
                onKeyDown={this.handleKeyPressDeadline}
                onChange={this.handleDeadlineInputChange}
                value={this.state.deadline}
              />
              <button
                id='clean-deadline-input'
                onClick={this.cleanDeadlineInput}
              >
                Limpar
              </button>
            </div>
          </div>
          <div className='error-msg'>{this.state.deadline_error}</div>

          <div className='input-block'>
            <label htmlFor='deadlineCreditPoints'>Prazo para Crédito dos Pontos (dias)</label>
            <span> (até 4 caracteres)</span>
            <input
              type='text'
              id='deadlineCreditPoints'
              name='deadlineCreditPoints'
              onChange={this.handleNumberInputChange}
              value={this.state.deadlineCreditPoints}
              maxLength='4'
            />
          </div>
          <div className='error-msg'>{this.state.deadlineCreditPoints_error}</div>

          <div className='input-block'>
            <label htmlFor='creditPhrasePoints'>Frase dos Créditos dos Pontos</label>
            <input
              type='text'
              id='creditPhrasePoints'
              name='creditPhrasePoints'
              onChange={this.handleTextInputChange}
              value={this.state.creditPhrasePoints}
            />
          </div>
          <div className='error-msg'>{this.state.creditPhrasePoints_error}</div>

          <div className='input-block'>
            <label htmlFor='urlImageOCC'>URL da Imagem da Campanha (URL da imagem cadastrada no OCC)</label>
            <input
              type='text'
              id='urlImageOCC'
              name='urlImageOCC'
              onChange={this.handleTextInputChange}
              value={this.state.urlImageOCC}
            />
          </div>

          <div className='input-block'>
            <label htmlFor='urlPagePartner'>URL da página da oferta do Parceiro</label>
            <input
              type='text'
              id='urlPagePartner'
              name='urlPagePartner'
              onChange={this.handleTextInputChange}
              value={this.state.urlPagePartner}
            />
          </div>
          <div className='error-msg'>{this.state.urlPagePartner_error}</div>

          <div>
            <input type="checkbox" name="uploadFileCampaignExpired" onChange={this.handleChangeCheck.bind()} checked={this.state.uploadFileCampaignExpired} />
            <label htmlFor="uploadFileCampaignExpired" className='checkBox-space' >
              Permitir subir novos arquivos de pontuação para a campanha expirada?
            </label>
          </div>

          <div className='input-block'>
            <label htmlFor='deadline'>Regulamento</label>
            <Editor
              handleTextEditorChange={this.handleTextEditorChange}
              ref='draftRef'
              contentHtml={this.state.text_editor_content_html}
            />
          </div>
          <div className='error-msg'>{this.state.text_editor_content_error}</div>

          <div className='input-block'>
            <label htmlFor='campaignRuleUrl'>Regulamento Completo</label>
            <input
              type='text'
              id='campaignRuleUrl'
              name='campaignRuleUrl'
              onChange={this.handleTextInputChange}
              value={this.state.campaignRuleUrl}
            />
          </div>


          <div className='button-wrapper'>
            <button
              type='submit'
              className='button-admin'
              id='send-form'
              disabled={this.state.disabled_button}
            >
              {this.state.disabled_button ? 'Enviando...' : 'Editar'}
            </button>

            {/* 
            <button
              onClick={this.handleDelete}
              className='button-admin'
              id='delete-form-campaign'
              disabled={this.state.disabled_button_delete}
            >
              {this.state.disabled_button_delete ? 'Enviando...' : 'Excluir'}
            </button>
            */}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ 
  editorValidated: state.editor.editorValidated,
  categories: state.select.categories });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setEditorValidated }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FormCampaignEdit);