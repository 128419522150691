import React, { Component } from "react";
export default class DetailsItem extends Component {
    render() {
        return (
            <li className="-containerdetail">
                <div className="-detailsitem">
                    <div className="-detailsiconbox">
                        <img src="../icon/ic-throw.png" alt="" />
                    </div>

                    <div className="-detailscontentinfo">
                        <div className="-detailsbox">
                            <div className="-textmain">Item</div>
                            <span className="-text">{this.props.detail.itemIdProduct}</span>
                            <span className="-text">{this.props.detail.itemIdSku}</span>
                        </div>

                        <div className="-detailsbox">
                            <div className="-textmain">Parceiro</div>
                            <span className="-text -partner">{this.props.detail.subsellerDescription}</span>
                        </div>

                        <div className="-detailsbox">
                            <div className="-textmain">Plano</div>
                            <span className="-text">{this.props.detail.paymentPlanName}</span>
                        </div>

                        <div className="-detailsbox">
                            <div className="-textmain">Valor Item</div>
                            <span className="-text">{this.props.detail.installmentAmount}</span>
                        </div>

                        <div className="-detailsbox">
                            <div className="-textmain">Parceiro</div>
                            <span className="-text">{this.props.detail.subsellerRateAmount}</span>
                        </div>

                        <div className="-detailsbox">
                            <div className="-textmain">Comissão ESF</div>
                            <span className="-text">{this.props.detail.subsellerRateAmountForCommission}</span>
                        </div>
                    </div>
                    {this.props.detail.cancellationAvailable && this.props.visibleButtonCancellation &&
                        <div className="-btncalcelleditem js-openmodalcancellation"
                            onClick={() => this.props.showModal(this.props.detail, this.props.orderId)}>
                            <img src="../icon/ic-error.svg" alt="Cancelar" />
                            <span>Cancelar</span>
                        </div>
                    }
                </div>
            </li>
        );
    }
}


