import React, { Component } from 'react';
import { ThemeProvider } from "styled-components";

import GlobalStyle from '../../styles/global';
import theme from '../../styles/theme';

import NavBar from "../../components/navbar/NavBar";
import Footer from "../../components/footer/Footer";

import moment from 'moment';
import axios from "axios";
import Upload from '../../components/liquidation/Upload';
import Menu from '../../components/liquidation/Menu';
import List from '../../components/liquidation/List';
import { cpf, cnpj } from '../../helpers/GlobalFunctions';
import OptionsAction from '../../components/liquidation/OptionsActions';
import Filter from '../../components/liquidation/Filter';
import Pagination from '../../components/transactions/pagination/Pagination';
import CancelSelected from '../../components/liquidation/CancelSelected';
import ModalCancelSelected from '../../components/liquidation/ModalCancelSelected';
const FileDownload = require('js-file-download');

export default class Liquidation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userAuth: props.state.authenticated && props.state.email && !props.state.modal,
            permission: props.state.isAdmin || props.state.isBackofficeGetnetReleasePayment,
            releases: [],
            loadingReleases: false,
            visibleFilter: false,
            filterDateInit: "",
            filterDateFrom: "",
            filterOrderId: "",
            filterCommerceItemId: "",
            filterState: "",
            currentPage: 1,
            pageBound: 3,
            pageNumbers: [],
            isPrevBtnActive: false,
            isNextBtnActive: false,
            currentFilter: {},
            showCancelModal: false,
            releasesToCancel: []
        };

        this.openFilter = this.openFilter.bind(this);
        this.closeFilter = this.closeFilter.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.handleDateInit = this.handleDateInit.bind(this);
        this.handleDateFrom = this.handleDateFrom.bind(this);
        this.handleCommerceItem = this.handleCommerceItem.bind(this);
        this.handleState = this.handleState.bind(this);
        this.handleOrder = this.handleOrder.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.submitFilter = this.submitFilter.bind(this);
        this.downloadCSV = this.downloadCSV.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.btnPrevClick = this.btnPrevClick.bind(this);
        this.btnNextClick = this.btnNextClick.bind(this);
        this.handleCancelSelection = this.handleCancelSelection.bind(this);        
        this.openModal = this.openModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    componentDidMount() {
        this.getTransactions();
    }

    openFilter() {
        this.setState({
            visibleFilter: true
        });
    }

    toggleFilter() {
        const filter = this.state.visibleFilter;

        this.setState({
            visibleFilter: !filter
        });

    }

    closeFilter() {
        this.setState({
            visibleFilter: false
        });
    }


    getTransactions(params) {
        axios
            .get(`${window.TRANSACTIONS_RELEASE}`, {
                params: params
            })
            .then((response) => {
                let data = response.data;
                let totalPages = data.totalPages;
                let currentPage = data.pageNumber + 1;
                let isNextBtnActive = false;
                let isPrevBtnActive = false;
                let pageNumbers = [];

                if (data.releases) {
                    let releases = this.listReleases(data.releases);                    
                    if (totalPages === currentPage && totalPages > 1) {
                        isPrevBtnActive = true;
                    } else if (currentPage === 1 && totalPages > 1) {
                        isNextBtnActive = true;
                    } else if (totalPages > 1) {
                        isNextBtnActive = true;
                        isPrevBtnActive = true;
                    }

                    for (let i = 1; i <= totalPages; i++) {
                        pageNumbers.push(i);
                    }

                    this.setState({
                        releases: releases,
                        loadingReleases: true,
                        pageNumbers: pageNumbers,
                        totalPages: totalPages,
                        currentPage: currentPage,
                        isNextBtnActive: isNextBtnActive,
                        isPrevBtnActive: isPrevBtnActive
                    })
                } else {
                    this.setState({
                        releases: [],
                        pageNumbers: [],
                        currentPage: 1,
                        isNextBtnActive: false,
                        isPrevBtnActive: false
                    })
                }


            })
            .catch((error) => {
                if (error.response) {
                    const httpStatusError = JSON.stringify(error.response.status);
                    if (httpStatusError === "401") {
                        this.props.logout();
                    }
                }
            });

    }

    listReleases(releases) {
        return releases.map(release => {
            return {
                commerceItemId: release.commerceItemId,
                creationDate: moment(release.creationDate).format("DD/MM/YYYY"),
                errorMessage: release.errorMessage,
                identifier: release.identifier ? this.formatIdentifier(release.identifier) : '-',
                orderId: release.orderId,
                orderSubmitDate: release.orderSubmitDate,
                partnerOrderId: release.partnerOrderId,
                paymentDateRelease: release.paymentDateRelease ? moment(release.paymentDateRelease).format("DD/MM/YYYY") : '-',
                retries: release.retries,
                seller: release.seller,
                siteId: release.siteId,
                skuId: release.skuId,
                showCheckbox: release.status.toLowerCase() === "waiting_release",
                status: release.status,
                itemValue: release.itemValue ? this.formatReal(release.itemValue) : '-',
                updateDate: moment(release.updateDate).format("DD/MM/YYYY"),
                selected: false
            }
        });
    }

    formatIdentifier(identifier) {
        var format;

        identifier.length <= 11 ? format = cpf(identifier) : format = cnpj(identifier)

        return format;
    }

    formatReal(cents) {
        const valueToFormatReal = cents ? (Number(cents) / 100) : 0;
        return valueToFormatReal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
    }

    status(status) {
        var descriptionStatus;

        status = status.toLowerCase();

        switch (status) {
            case 'need_release':
                descriptionStatus = "Não Liberado";
                break;
            case 'waiting_release':
                descriptionStatus = "Aguardando Liberação";
                break;
            case 'complete':
                descriptionStatus = "Liquidado";
                break;
            case 'failed':
                descriptionStatus = "Falha na Liquidação";
                break;
            case 'waiting_release_getnet':
                descriptionStatus = "Aguardando Liberação GetNet";
                break;
            default:
                descriptionStatus = status;
        }

        return descriptionStatus;
    }

    handleDateInit(event) {
        this.setState({ filterDateInit: event.target.value });
    }

    handleDateFrom(event) {
        this.setState({ filterDateFrom: event.target.value });
    }

    handleCommerceItem(event) {
        this.setState({ filterCommerceItemId: event.target.value });
    }

    handleState(event) {
        let state = event.target.previousSibling;
        state = state ? state.value : "";

        this.setState({ filterState: state });
    }

    handleOrder(event) {
        this.setState({ filterOrderId: event.target.value });
    }

    submitFilter() {
        const params = {}

        if (this.state.filterDateInit)
            params.startDate = this.state.filterDateInit;
        
        if (this.state.filterDateFrom)
            params.endDate = this.state.filterDateFrom;

        if (this.state.filterCommerceItemId)
            params.commerceItem = this.state.filterCommerceItemId;
        
        if (this.state.filterOrderId)
            params.orderId = this.state.filterOrderId;
        
        if (this.state.filterState)
            params.status = this.state.filterState;
        
        this.setState({
            currentFilter: params
        })

        this.getTransactions(params);
    }

    clearFilter() {
        console.log('limpo')

        this.setState({
            filterDateInit: "",
            filterDateFrom: "",
            filterCommerceItemId: "",
            filterOrderId: "",
            filterState: "",
            currentFilter: {},
            releases: []
        });

        this.getTransactions({});

    }

    fileNameCSV() {
        var date = moment().format('DDMMYYYY');
        var hour = moment().format('HHmmss');

        return "releases_" + date + "_" + hour;
    }

    isValidDateLength(value) {
        return value && value.length === 10;
    }

    downloadCSV() {
        const TRANSACTIONS_RELEASE_DOWNLOAD = `${window.TRANSACTIONS_RELEASE_DOWNLOAD}`;
        const params = {};
        
        if (this.isValidDateLength(this.state.filterDateInit))
            params.startDate = this.isValidDateLength(this.state.filterDateInit);
        
        if (this.isValidDateLength(this.state.filterDateFrom))
            params.endDate = this.isValidDateLength(this.state.filterDateFrom);

        if (this.state.filterCommerceItemId)
            params.commerceItem = this.state.filterCommerceItemId;
        
        if (this.state.filterOrderId)
            params.orderId = this.state.filterOrderId;
        
        if (this.state.filterState)
            params.status = this.state.filterState;

        if (this.state.releases.length > 0) {
            axios
                ({
                    method: 'get',
                    url: TRANSACTIONS_RELEASE_DOWNLOAD,
                    params: params,
                    responseType: 'blob'
                })
                .then((response) => {
                    FileDownload(response.data, this.fileNameCSV() + '.csv');
    
                })
                .catch((error) => {
                    if (error.response) {
                        const httpStatusError = JSON.stringify(error.response.status);
                        if (httpStatusError === "401") {
                            this.props.logout();
                        }
                    }
                });
        }
    }

    handleClick(event) {
        let currentPage = Number(event.target.id) - 1;
        let params = this.state.currentFilter;
        params.page = currentPage;
        this.getTransactions(params);
    }

    btnPrevClick() {
        let currentPage = this.state.currentPage - 2;
        let params = this.state.currentFilter;
        params.page = currentPage;
        this.getTransactions(params);
    }

    btnNextClick() {
        let currentPage = this.state.currentPage;
        let params = this.state.currentFilter;
        params.page = currentPage;
        this.getTransactions(params);
    }

    handleCancelSelection(event) {
        let index = event.target.value;
        let releases = this.state.releases;
        releases[index].selected = !releases[index].selected;
        this.setState({
            releases: releases
        })
    }
    
    openModal() {
        let selectedReleases = this.state.releases.filter(release => {
            return release.selected == true;
        });

        this.setState({
            releasesToCancel: selectedReleases,
            showCancelModal: true
        })
    }
    
    hideModal() {
        this.setState({
            showCancelModal: false
        })
    }


    render() {
        return (
            <ThemeProvider theme={theme}>
                {this.state.userAuth && this.state.permission &&
                    <div className="App saleoff digital-transactions">
                        <NavBar
                            keycloak={this.props.state.keycloak}
                            logout={this.props.logout}
                            name={this.props.state.name}
                            email={this.props.state.email}
                        />

                        <main>
                            <Menu />
                            <Upload state={this.props.state} />
                            <OptionsAction
                                openFilter={this.openFilter}
                                closeFilter={this.closeFilter}
                                toggleFilter={this.toggleFilter}
                                downloadCSV={this.downloadCSV}
                            />
                            <Filter
                                visibleFilter={this.state.visibleFilter}
                                openFilter={this.openFilter}
                                closeFilter={this.closeFilter}
                                filterDateInit={this.state.filterDateInit}
                                filterDateFrom={this.state.filterDateFrom}
                                filterCommerceItemId={this.state.filterCommerceItemId}
                                filterState={this.state.filterState}
                                filterOrderId={this.state.filterOrderId}
                                handleDateInit={this.handleDateInit}
                                handleDateFrom={this.handleDateFrom}
                                handleCommerceItem={this.handleCommerceItem}
                                handleState={this.handleState}
                                handleOrder={this.handleOrder}
                                clearFilter={this.clearFilter}
                                submitFilter={this.submitFilter}
                            />
                            <ModalCancelSelected
                                releases={this.state.releasesToCancel} 
                                showModal={this.state.showCancelModal} 
                                hideModal={this.hideModal} 
                                resetFilterAndTransactions={this.clearFilter}
                            />
                            <List releases={this.state.releases} handleCancelSelection={this.handleCancelSelection}>
                                <CancelSelected releases={this.state.releases} resetFilterAndTransactions={this.clearFilter} openModal={this.openModal}/>
                                <Pagination
                                    isPrevBtnActive={this.state.isPrevBtnActive}
                                    isNextBtnActive={this.state.isNextBtnActive}
                                    pageNumbers={this.state.pageNumbers}
                                    currentPage={this.state.currentPage}
                                    pageBound={this.state.pageBound}
                                    btnNextClick={this.btnNextClick}
                                    btnPrevClick={this.btnPrevClick}
                                    handleClick={this.handleClick}
                                />
                            </List>
                        </main>
                        <Footer />
                    </div>
                }
                <GlobalStyle />
            </ThemeProvider>


        )
    }
}
