
/**
 * 
 * @param {array} options ex [{...}]
 * @param {array} ids ex [1,2,3]
 * @param {boolean} except exclude ids
 * @returns array ex [{...}]
 */
const getOptionsByIds = (options, ids, except) => {
    return options.filter(({categoryId}) => except 
                ? !ids.includes(categoryId) 
                : ids.includes(categoryId)
            )
}

/**
 * 
 * @param {array} options ex [{...}]
 * @param {array} ids  ex [1,2,3]
 * @param {string} name ex 'disabled' || 'checked'
 * @param {any} value true || false
 * @returns array ex [{...}]
 */
const updateItemsByIds = (options, ids, name, value) => {
    return options.map(item => ids.includes(item.categoryId) 
            ? {...item, [name]: value }
            : item
    )
}

const groupItemsByParityAndClubParity = (categories) => {
    const group = {};

    categories.forEach(item => {
        const key = `${item.parity}-${item.clubParity}`;
        if (!group[key]) {
            group[key] = { list: [], parity: item.parity, clubParity: item.clubParity };
        }
        group[key].list.push({
            fullCategoryName: `${item.partnerCategoryId} - ${item.categoryName}`,
            categoryName: item.categoryName,
            categoryId: item.categoryId,
            checked: true
        });
    });

    return Object.values(group);
}

const listIdsByChecked = (list) => {
    return  list
            .filter(({checked}) => checked)
            .map(({categoryId}) => categoryId)
}

/**
 * 
 * @param {array} categories list categories
 * @param {array} options items selected from list
 * @param {array} otherCategories other itens not selected
 * @returns 
 */
const populateOtherCategories = (categories, options, otherCategories) => {
    return categories.map(item => {
        const list = options.map(option =>
            !item.list.map(({ categoryId }) => categoryId).includes(option.categoryId)
                ? { ...option, disabled: true }
                : { ...option, checked: true }
        )
        return { 
            ...item, 
            list: sortItems([
                ...list, 
                ...otherCategories
            ], "categoryName") 
        }
    })
}

const sortItems = (data, name) => {
    return data.sort(function (a, b) {
        if (a[name] < b[name]) {
            return -1;
        }
        if (a[name] > b[name]) {
            return 1;
        }
        return 0;
    });
}

const sanitizeCategories = (categories) => {
    const payload = [];
  
    categories.forEach(category => {
      category.list.forEach(item => {
        item.checked &&
            payload.push({
                categoryId: item.categoryId,
                categoryName: item.categoryName,
                parity: category.parity,
                clubParity: category.clubParity,
            });
      });
    });
  
    return payload;
}

const generateToken = () => Math.floor(Math.random() * 90000) + 10000

const sumParities = (esferaParity, partnerParity) => parseFloat( 
    (parseFloat(esferaParity || '0') + parseFloat(partnerParity || '0')).toFixed(2) 
);

const maskParity = (value) => {
    const parts = value
        .replace(/[^\d\.]/g, '')
        .split('.');
    if (parts.length > 1) {
      parts[1] = parts[1].substr(0, 2);
    }
    let newValue = parts.join('.');
    if (parseFloat(newValue) >= 100) {
      newValue = 99.99;
    }
    return newValue;
}

export default {
    groupItemsByParityAndClubParity,
    populateOtherCategories,
    sanitizeCategories,
    updateItemsByIds,
    listIdsByChecked,
    getOptionsByIds,
    generateToken,
    sumParities,
    maskParity,
    sortItems
}