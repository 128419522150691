import React, { Component } from "react";;

export default class Loading extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        return (
            <>
                {this.props.loading &&
                    <div className="mdlloading js-mdlcancellation">
                        <div className="util-modal-background">
                            <div className="util-modal-center -modalbox">
                                <div>
                                    <div className="spinner-border text-danger" role="status"></div>
                                    <div className="font-weight-bold">Aguarde...</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}


