import React, { Component } from "react";
import axios from "axios";
import {sessionExpired, generalError} from '../../../helpers/GlobalMessages'


const initialState = {
  partner_name: "",
  partner_code: "",
  subPartner_name: "",
  subPartner_code: "",
  isDuplicate: false,
  valueShow: [],
  subPartner_code_locations: false,

  disabled_button: false,

  partner_name_error: "",
  partner_code_error: "",
  subPartner_name_error: "",
  subPartner_code_error: "",
};
export default class FormPartner extends Component {
  constructor(props) {
    super(props);
    this.capitalizePartnerName = this.capitalizePartnerName.bind(this);
    this.handleSubPartnerCodeNameInputChange = this.handleSubPartnerCodeNameInputChange.bind(this);
    this.validadeInputPermissionLetter = this.validadeInputPermissionLetter.bind(this);
  }

  state = initialState;

  validade = () => {
    let partner_name_error = "";
    let partner_code_error = "";
    let subPartner_name_error = "";
    let subPartner_code_error = "";

    if (!this.state.subPartner_name && this.state.subPartner_code_locations && this.state.valueShow.length === 0) {
      subPartner_name_error = "Nome do subparceiro inválido";
    }

    if (!this.state.subPartner_code && this.state.subPartner_code_locations && this.state.valueShow.length === 0) {
      subPartner_code_error = "Código do subparceiro inválido";
    }

    if (this.state.subPartner_code.length < 3 && this.state.subPartner_code_locations && this.state.valueShow.length === 0) {
      subPartner_code_error = "O código do subparceiro deve conter 3 caracteres";
    }

    if (!this.state.partner_name) {
      partner_name_error = "Nome do parceiro inválido";
    }

    if (!this.state.partner_code) {
      partner_code_error = "Código do parceiro inválido";
    }

    if (this.state.partner_code.length < 3) {
      partner_code_error = "O código do parceiro deve conter 3 caracteres";
    }

    if (
      this.state.partner_code.length === 3 &&
      !(/^[a-zA-Z0-9]*[a-zA-Z]+[a-zA-Z0-9]*$/.test(this.state.partner_code))
    ) {
      partner_code_error = "O código do parceiro deve conter letras e números ou somente letras";
    }

    if (partner_name_error || partner_code_error || subPartner_name_error || subPartner_code_error) {
      this.setState({
        partner_name_error,
        partner_code_error,
        subPartner_name_error,
        subPartner_code_error,
      });
      return false;
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const isValid = this.validade();

    if (isValid) {
      event.preventDefault();

      this.capitalizePartnerName().then(() => {
        if (this.state.disabled_button) {
          return;
        }

        this.setState({
          disabled_button: true,
        });

        const config = {
          headers: {
            "content-type": "application/json",
          },
        };

        const partner = {
          partnerName: this.state.partner_name,
          partnerCode: this.state.partner_code
        };

        if (this.state.subPartner_code_locations) {
          partner.subPartners = this.state.valueShow;
        }

        const BASE_URL = `${window.REACT_APP_URL}`;

        axios
          .post(`${BASE_URL}/partner`, partner, config)
          .then((response) => {
            this.setState(initialState);
            document.getElementById("form-container").reset();
            this.props.handleSendFormPartnerSuccess(
              "Parceiro cadastrado com sucesso!"
            );
          })
          .catch((error) => {
            this.setState(initialState);
            document.getElementById("form-container").reset();

            if (error.response) {
              const httpStatusError = JSON.stringify(error.response.status);
              if (httpStatusError === '401') {
                this.props.handleSendFormPartnerFail(sessionExpired());
                return;
              } else {
                const message = error.response?.data?.results?.userMessage;
                if (message) {
                  this.props.handleSendFormPartnerFail(message);
                  return;
                }
              }
            }
            this.props.handleSendFormPartnerFail(generalError());
          });
      });
    }
  };

  handlePartnerNameInputChange = (event) => {
    event.preventDefault();
    this.setState({
      partner_name: event.target.value,
      partner_name_error: "",
      successMsg: "",
    });
  };

  async capitalizePartnerName() {
    this.setState({
      partner_name:
        this.state.partner_name[0].toUpperCase() +
        this.state.partner_name.substr(1),
    });
  }

  handlePartnerCodeInputChange = (event) => {
    event.preventDefault();
    this.setState({
      partner_code: event.target.value.toUpperCase(),
      successMsg: "",
    });
    if (event.target.value.length === 3) {
      this.setState({
        partner_code_error: "",
      });
    }
  };

  handleSubPartnerNameInputChange = (event) => {
    event.preventDefault();
    this.setState({
      subPartner_name: event.target.value,
      subPartner_name_error: "",
      successMsg: "",
    });
  };

  handleSubPartnerCodeInputChange = (event) => {
    event.preventDefault();
    this.setState({
      subPartner_code: event.target.value.toUpperCase(),
      successMsg: "",
    });
    if (event.target.value.length === 3) {
      this.setState({
        subPartner_code_error: "",
      });
    }
  };

  handleChangeCheck = (event) => {
    this.setState({
      [event.target.name]: !this.state[event.target.name],
    });
  };

  handleSubPartnerCodeNameInputChange = () => {
    const { subPartner_code, subPartner_name } = this.state;
    const isValid = this.validade();

    if(!isValid) return

    const newSubpartner = {
      subPartnerName: subPartner_name,
      subPartnerCode: subPartner_code
    };
  
    this.setState(prevState => ({
      valueShow: [...prevState.valueShow, newSubpartner],
      subPartner_code: '',
      subPartner_name: ''
  }));
  };

  handleRemoveSubpartner = (index) => {
    this.setState(prevState => ({
        valueShow: prevState.valueShow.filter((subpartner, i) => i !== index)
    }));
  };

  isDuplicate(index) {
    const { valueShow } = this.state;
    const currentSubpartner = valueShow[index];
    const isDuplicate = valueShow.some((subpartner, subIndex) => {

      if (index !== subIndex) {
        return (
            subpartner.subPartnerCode === currentSubpartner.subPartnerCode
        );
      }
      return false;
    });

    return isDuplicate;
  }

  validadeInputPermissionLetter(event){
    const input = event.target;
    const name = event.target.name;
    input.value = input.value.replace(/[^a-zA-ZÀ-ÿ\s]/g, '');
    
    this.applyTransformation(name, input)
  }

  applyTransformation = (name, input) => {
    const transformations = {
      'subPartner_name': input => input.trimStart(),
      'subPartner_code': input => input.replace(/\s/g, '')
    };
    const transformFunction = transformations[name];

    if (!transformFunction) return;
    
    input.value = transformFunction(input.value);
  }

  render() {
    return (
      <div id="page-wrapper">
        <form
          id="form-container"
          className="container"
          onSubmit={this.handleSubmit}
        >
          <div className="input-block">
            <label htmlFor="partner_name">Nome do Parceiro</label>
            <input
              type="text"
              id="partner_name"
              name="partner_name"
              onChange={this.handlePartnerNameInputChange}
              maxLength="100"
            />
          </div>
          <div className="error-msg">{this.state.partner_name_error}</div>

          <div className="input-block">
            <label htmlFor="partner_code">Código do Parceiro</label>
            <span> (3 caracteres)</span>
            <input
              type="text"
              id="partner_code"
              name="partner_code"
              onChange={this.handlePartnerCodeInputChange}
              maxLength="3"
            />
          </div>
          <div className="error-msg">{this.state.partner_code_error}</div>

          <div>
            <input
              type="checkbox"
              id="subPartner_code"
              name="subPartner_code_locations"
              onChange={this.handleChangeCheck.bind()} 
              checked={this.state.subPartner_code_locations}
            />
            <label htmlFor="subPartner_code_locations" className='checkBox-space'>Cadastrar Subparceiros?</label>

            {
              this.state.subPartner_code_locations ? (
                <div>
                  {this.state.valueShow.map((subpartner, index) => (
                    <div key={index} className={`boxContainer_subpartner ${this.isDuplicate(index) ? 'error_duplicate' : ''}`}>
                      <div className={`box_subpartner ${this.isDuplicate(index) ? 'error_duplicate' : ''}`}>
                        <label className="label_subpartner">
                            {subpartner.subPartnerCode} - {subpartner.subPartnerName}
                        </label>
                        <button
                            type="button"
                            className={`button_remove ${this.isDuplicate(index) ? 'error_duplicate_button_remove' : ''}`}
                            onClick={() => this.handleRemoveSubpartner(index)}
                        >
                            <span aria-hidden="true">x</span>
                        </button>
                      </div>
                    </div>
                  ))}

                  <div className="input-block">
                    <label htmlFor="subPartner_name">Nome do Subparceiro?</label>
                    <input
                      type="text"
                      id="subPartner_name"
                      name="subPartner_name"
                      value={this.state.subPartner_name}
                      onChange={this.handleSubPartnerNameInputChange}
                      onInput={this.validadeInputPermissionLetter}
                      maxLength="255"
                    />
                  </div>
                  <div className="error-msg">{this.state.subPartner_name_error}</div>

                  <div className="input-block">
                    <label htmlFor="subPartner_code">Código do Subparceiro</label>
                    <span> (3 caracteres)</span>
                    <input
                      type="text"
                      id="subPartner_code"
                      name="subPartner_code"
                      value={this.state.subPartner_code}
                      onChange={this.handleSubPartnerCodeInputChange}
                      onInput={this.validadeInputPermissionLetter}
                      pattern="[^\s]+"
                      maxLength="3"
                    />
                  </div>
                  <div className="error-msg">{this.state.subPartner_code_error}</div>

                  <div className="button-wrapper-add">
                    <button
                      type="button"
                      className="button-add"
                      onClick={this.handleSubPartnerCodeNameInputChange}
                      disabled={!this.state.subPartner_name || !this.state.subPartner_code}
                    >
                      <span>ADD</span>
                    </button>
                  </div>

                </div>
              ): null
            }
          </div>
          
          <div className="button-wrapper">
            <button
              className="button-admin"
              id="send-form"
              disabled={this.state.disabled_button}
            >
              {this.state.disabled_button ? "Enviando..." : "Enviar"}
            </button>
          </div>
        </form>
      </div>
    );
  }
}
