import React, { Component } from "react";;

export default class LoadingTransactions extends Component {

    render() {
        return (
            <>
                {!this.props.loadingTransactions &&
                    <div className="mdlloading js-mdlcancellation">
                        <div className="util-modal-background">
                            <div className="util-modal-center -modalbox">
                                <div>
                                    <div className="spinner-border text-danger" role="status"></div>
                                    <div className="font-weight-bold">Carregando... Aguarde</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}


