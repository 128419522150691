import React, { Component } from "react";

import NavBar from "../../components/navbar/NavBar";
import OptionsMenu from "../../components/optionsMenu/OptionsMenu";
import ImportView from "../../views/import/ImportView";
import Footer from "../../components/footer/Footer";

import ListPartners from "../../components/partners/list/ListPartners";
import FormPartner from "../../components/partners/form/FormPartner";

import ListSubPartners from "../../components/subPartners/list/ListSubPartners";
import FormSubPartner from "../../components/subPartners/form/FormSubPartner";

import ListCampaigns from "../../components/campaigns/list/ListCampaigns";
import FormCampaign from "../../components/campaigns/form/FormCampaign";

import ListCooperator from "../../components/financial/list/ListCooperator";
import FormCooperator from "../../components/financial/form/FormCooperator";

import ConfigTerms from "../../components/configuration/ConfigTerms";

export default class AdminHomeView extends Component {
  constructor(props) {
    super(props);

    this.handleSubPartnersListClick = this.handleSubPartnersListClick.bind(this);
    this.handleCreateSubPartnersClick = this.handleCreateSubPartnersClick.bind(this);
    
    this.handlePartnerAreaClick = this.handlePartnerAreaClick.bind(this);
    this.handlePartnersListClick = this.handlePartnersListClick.bind(this);
    this.handleCreatePartnerClick = this.handleCreatePartnerClick.bind(this);

    this.handleCampaignAreaClick = this.handleCampaignAreaClick.bind(this);
    this.handleCampaignsListClick = this.handleCampaignsListClick.bind(this);
    this.handleCreateCampaignClick = this.handleCreateCampaignClick.bind(this);

    this.handleImportAreaClick = this.handleImportAreaClick.bind(this);
    this.handleConfigurationAreaClick = this.handleConfigurationAreaClick.bind(this);
    this.handleConfigEditTermsClick = this.handleConfigEditTermsClick.bind(this);

    this.handleConfigRegisterCooperatorClick = this.handleConfigRegisterCooperatorClick.bind(this);
    this.handleConfigFormCreateCooperatorClick = this.handleConfigFormCreateCooperatorClick.bind(this);

    this.handleMessageSuccess = this.handleMessageSuccess.bind(this);
    this.handleMessageFail = this.handleMessageFail.bind(this);

    this.hideAllOtherComponents = this.hideAllOtherComponents.bind(this);
    this.cleanMessages = this.cleanMessages.bind(this);

    this.state = {
      buttonsPartnerVisible: false,
      partnerListVisible: false,
      partnerFormVisible: false,

      buttonsCampaignVisible: false,
      campaignListVisible: false,
      campaignFormVisible: false,

      importAreaVisible: false,
      configurationVisible: false,
      configEditTermsVisible: false,

      configRegisterCooperatorVisible: false,
      configFormCreateCooperatorVisible: false,

      subPartnerListVisible: false,
      subPartnerFormVisible: false,

      partners: [],
      successMsg: "",
      failMsg: "",
    };
  }

  handlePartnerAreaClick() {
    this.setState({
      buttonsPartnerVisible: true,
      partnerListVisible: false,
      partnerFormVisible: false,

      buttonsCampaignVisible: false,
      campaignListVisible: false,
      campaignFormVisible: false,

      importAreaVisible: false,
      configurationVisible: false,
      configEditTermsVisible: false,

      subPartnerListVisible: false,
      subPartnerFormVisible: false,

      configRegisterCooperatorVisible: false,
      configFormCreateCooperatorVisible: false,
    });
    this.cleanMessages();
  }

  handleConfigurationAreaClick() {
    this.setState({
      buttonsPartnerVisible: false,
      partnerListVisible: false,
      partnerFormVisible: false,

      buttonsCampaignVisible: false,
      campaignListVisible: false,
      campaignFormVisible: false,

      importAreaVisible: false,
      configurationVisible: true,
      configEditTermsVisible: false,

      subPartnerListVisible: false,
      subPartnerFormVisible: false,

      configRegisterCooperatorVisible: false,
      configFormCreateCooperatorVisible: false,
    });
    this.cleanMessages();
  }

  handleConfigEditTermsClick() {
    this.setState({
      buttonsPartnerVisible: false,
      partnerListVisible: false,
      partnerFormVisible: false,

      buttonsCampaignVisible: false,
      campaignListVisible: false,
      campaignFormVisible: false,

      importAreaVisible: false,
      configurationVisible: false,
      configEditTermsVisible: true,

      subPartnerListVisible: false,
      subPartnerFormVisible: false,

      configRegisterCooperatorVisible: false,
      configFormCreateCooperatorVisible: false,
    });
    this.cleanMessages();
  }

  handleConfigRegisterCooperatorClick() {
    this.setState({
      buttonsPartnerVisible: false,
      partnerListVisible: false,
      partnerFormVisible: false,

      buttonsCampaignVisible: false,
      campaignListVisible: false,
      campaignFormVisible: false,

      importAreaVisible: false,
      configurationVisible: false,
      configEditTermsVisible: false,

      subPartnerListVisible: false,
      subPartnerFormVisible: false,

      configRegisterCooperatorVisible: true,
      configFormCreateCooperatorVisible: false,
    });
    this.cleanMessages();
  }

  handleConfigFormCreateCooperatorClick() {
    this.setState({
      buttonsPartnerVisible: false,
      partnerListVisible: false,
      partnerFormVisible: false,

      buttonsCampaignVisible: false,
      campaignListVisible: false,
      campaignFormVisible: false,

      importAreaVisible: false,
      configurationVisible: false,
      configEditTermsVisible: false,

      subPartnerListVisible: false,
      subPartnerFormVisible: false,

      configRegisterCooperatorVisible: false,
      configFormCreateCooperatorVisible: true,
    });
    this.cleanMessages();
  }

  handlePartnersListClick() {
    this.setState({
      buttonsPartnerVisible: false,
      partnerListVisible: true,
      partnerFormVisible: false,

      buttonsCampaignVisible: false,
      campaignListVisible: false,
      campaignFormVisible: false,

      importAreaVisible: false,
      configurationVisible: false,
      configEditTermsVisible: false,

      subPartnerListVisible: false,
      subPartnerFormVisible: false,

      configRegisterCooperatorVisible: false,
      configFormCreateCooperatorVisible: false,
    });
    this.cleanMessages();
  }

  handleCreatePartnerClick() {
    this.setState({
      buttonsPartnerVisible: false,
      partnerListVisible: false,
      partnerFormVisible: true,

      buttonsCampaignVisible: false,
      campaignListVisible: false,
      campaignFormVisible: false,

      importAreaVisible: false,
      configurationVisible: false,
      configEditTermsVisible: false,

      subPartnerListVisible: false,
      subPartnerFormVisible: false,

      configRegisterCooperatorVisible: false,
      configFormCreateCooperatorVisible: false,
    });
    this.cleanMessages();
  }

  handleSubPartnersListClick() {
    this.setState({
      buttonsPartnerVisible: false,
      partnerListVisible: false,
      partnerFormVisible: false,

      buttonsCampaignVisible: false,
      campaignListVisible: false,
      campaignFormVisible: false,

      importAreaVisible: false,
      configurationVisible: false,
      configEditTermsVisible: false,

      subPartnerListVisible: true,
      subPartnerFormVisible: false,

      configRegisterCooperatorVisible: false,
      configFormCreateCooperatorVisible: false,
    });
    this.cleanMessages();
  }

  handleCreateSubPartnersClick() {
    this.setState({
      buttonsPartnerVisible: false,
      partnerListVisible: false,
      partnerFormVisible: false,

      buttonsCampaignVisible: false,
      campaignListVisible: false,
      campaignFormVisible: false,

      importAreaVisible: false,
      configurationVisible: false,
      configEditTermsVisible: false,

      subPartnerListVisible: false,
      subPartnerFormVisible: true,

      configRegisterCooperatorVisible: false,
      configFormCreateCooperatorVisible: false,
    });
    this.cleanMessages();
  }

  handleCampaignAreaClick() {
    this.setState({
      buttonsPartnerVisible: false,
      partnerListVisible: false,
      partnerFormVisible: false,

      buttonsCampaignVisible: true,
      campaignListVisible: false,
      campaignFormVisible: false,

      importAreaVisible: false,
      configurationVisible: false,
      configEditTermsVisible: false,

      subPartnerListVisible: false,
      subPartnerFormVisible: false,

      configRegisterCooperatorVisible: false,
      configFormCreateCooperatorVisible: false,
    });
    this.cleanMessages();
  }

  handleCampaignsListClick() {
    this.setState({
      buttonsPartnerVisible: false,
      partnerListVisible: false,
      partnerFormVisible: false,

      buttonsCampaignVisible: false,
      campaignListVisible: true,
      campaignFormVisible: false,

      importAreaVisible: false,
      configurationVisible: false,
      configEditTermsVisible: false,

      subPartnerListVisible: false,
      subPartnerFormVisible: false,

      configRegisterCooperatorVisible: false,
      configFormCreateCooperatorVisible: false,
    });
    this.cleanMessages();
  }

  handleCreateCampaignClick() {
    this.setState({
      buttonsPartnerVisible: false,
      partnerListVisible: false,
      partnerFormVisible: false,

      buttonsCampaignVisible: false,
      campaignListVisible: false,
      campaignFormVisible: true,

      importAreaVisible: false,
      configurationVisible: false,
      configEditTermsVisible: false,

      subPartnerListVisible: false,
      subPartnerFormVisible: false,

      configRegisterCooperatorVisible: false,
      configFormCreateCooperatorVisible: false,
    });
    this.cleanMessages();
  }

  handleImportAreaClick() {
    this.setState({
      buttonsPartnerVisible: false,
      partnerListVisible: false,
      partnerFormVisible: false,

      buttonsCampaignVisible: false,
      campaignListVisible: false,
      campaignFormVisible: false,

      importAreaVisible: true,
      configurationVisible: false,
      configEditTermsVisible: false,

      subPartnerListVisible: false,
      subPartnerFormVisible: false,

      configRegisterCooperatorVisible: false,
      configFormCreateCooperatorVisible: false,
    });
    this.cleanMessages();
  }

  cleanMessages() {
    this.setState({
      successMsg: "",
      failMsg: "",
    });
  }

  // Error message functions
  handleMessageSuccess(message) {
    this.setState({
      successMsg: message,
      failMsg: "",
    });
    this.hideAllOtherComponents();
  }

  handleMessageFail(message) {
    this.setState({
      failMsg: message,
      successMsg: "",
    });
    this.hideAllOtherComponents();
  }

  hideAllOtherComponents() {
    this.setState({
      buttonsPartnerVisible: false,
      partnerListVisible: false,
      partnerFormVisible: false,
      buttonsCampaignVisible: false,
      campaignListVisible: false,
      campaignFormVisible: false,
      importAreaVisible: false,
      configurationVisible: false,
      configEditTermsVisible: false,
      configRegisterCooperatorVisible: false,
      configFormCreateCooperatorVisible: false,
    });
  }

  render() {
    return (
      <div>
        <NavBar
          keycloak={this.props.keycloak}
          logout={this.props.logout}
          name={this.props.name}
          email={this.props.email}
        />

        <OptionsMenu
          showPartnerArea={this.handlePartnerAreaClick}
          showCampaignArea={this.handleCampaignAreaClick}
          showImportArea={this.handleImportAreaClick}
          showConfigurationArea={this.handleConfigurationAreaClick}
          isPartner={this.props.isPartner}
          isBackoffice={this.props.isBackoffice}
          isAdmin={this.props.isAdmin}
          isAdminCashbackFiles={this.props.isAdminCashbackFiles}
          isBackofficeCashbackFiles={this.props.isBackofficeCashbackFiles}
          site={this.props.site}
          keycloak={this.props.keycloak}
          logout={this.props.logout}
          company={this.props.company}
        />

        {this.state.buttonsPartnerVisible ? (
          <div id="page-buttons">
            <div id="list-container">
              <button onClick={this.handleCreatePartnerClick}>
                Cadastrar novo parceiro
              </button>

              <button onClick={this.handlePartnersListClick}>
                Visualizar parceiros cadastrados
              </button>

              <button onClick={this.handleCreateSubPartnersClick}>
                Cadastrar novo subparceiro
              </button>

              <button onClick={this.handleSubPartnersListClick}>
                Visualizar subparceiros cadastrados
              </button>
            </div>
          </div>
        ) : null}

        {this.state.partnerListVisible ? (
          <ListPartners 
            keycloak={this.props.keycloak} 
            logout={this.props.logout}
          />
        ) : null}

        {this.state.partnerFormVisible ? (
          <FormPartner
            handleSendFormPartnerSuccess={this.handleMessageSuccess}
            handleSendFormPartnerFail={this.handleMessageFail}
            keycloak={this.props.keycloak}
            logout={this.props.logout}
          />
        ) : null}

        {this.state.subPartnerListVisible ? (
          <ListSubPartners 
            keycloak={this.props.keycloak} 
            logout={this.props.logout}
          />
        ) : null}

        {this.state.subPartnerFormVisible ? (
          <FormSubPartner
            handleSendFormPartnerSuccess={this.handleMessageSuccess}
            handleSendFormPartnerFail={this.handleMessageFail}
            keycloak={this.props.keycloak}
            logout={this.props.logout}
          />
        ) : null}

        {this.state.buttonsCampaignVisible ? (
          <div id="page-buttons">
            <div id="list-container">
              <button onClick={this.handleCreateCampaignClick}>
                Cadastrar nova campanha
              </button>

              <button onClick={this.handleCampaignsListClick}>
                Editar campanhas cadastradas
              </button>
            </div>
          </div>
        ) : null}

        {this.state.campaignListVisible ? (
          <ListCampaigns
            handleSendFormCampaignSuccess={this.handleMessageSuccess}
            handleSendFormCampaignFail={this.handleMessageFail}
            showImportArea={this.handleImportAreaClick}
            keycloak={this.props.keycloak}
            logout={this.props.logout}
            isAdmin={this.props.isAdmin}
          />
        ) : null}

        {this.state.campaignFormVisible ? (
          <FormCampaign
            handleSendFormCampaignSuccess={this.handleMessageSuccess}
            handleSendFormCampaignFail={this.handleMessageFail}
            keycloak={this.props.keycloak}
            logout={this.props.logout}
          />
        ) : null}

        {this.state.importAreaVisible ? (
          <ImportView
            isAdmin={this.props.isAdmin}
            keycloak={this.props.keycloak}
            logout={this.props.logout}
          />
        ) : null}

        {this.state.configurationVisible ? (
          <div id="page-buttons">
            <div id="list-container">
              <button onClick={this.handleConfigEditTermsClick}>
                Editar Termos e Condições
              </button>

              <button onClick={this.handleConfigRegisterCooperatorClick}>
                Cadastro de e-mails de colaboradores do Financeiro
              </button>
            </div>
          </div>
        ) : null}

        {this.state.configEditTermsVisible ? (
          <ConfigTerms
            handleSendFormTermsSuccess={this.handleMessageSuccess}
            handleSendFormTermsFail={this.handleMessageFail}
            keycloak={this.props.keycloak}
            logout={this.props.logout}
          />
        ) : null}

        {this.state.configRegisterCooperatorVisible ? (
          <ListCooperator
            handleSendFormCooperatorSuccess={this.handleMessageSuccess}
            handleSendFormCooperatorFail={this.handleMessageFail}
            handleConfigFormCreateCooperatorClick={this.handleConfigFormCreateCooperatorClick}
            keycloak={this.props.keycloak}
            logout={this.props.logout}
          />
        ) : null}

        {this.state.configFormCreateCooperatorVisible ? (
          <FormCooperator
            handleSendFormCooperatorSuccess={this.handleMessageSuccess}
            handleSendFormCooperatorFail={this.handleMessageFail}
            keycloak={this.props.keycloak}
            logout={this.props.logout}
          />
        ) : null}

        <div className="success-msg">{this.state.successMsg}</div>
        <div className="fail-msg">{this.state.failMsg}</div>

        <Footer />
      </div>
    );
  }
}
