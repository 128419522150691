import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import setPagination from "./PaginationActions";

class ManualPagination extends Component {
    constructor(props) {
        super(props);

        this.onCreatePaginationField = this.onCreatePaginationField.bind(this);
        this.onChangeManualPagination = this.onChangeManualPagination.bind(this);
        this.handleLoad = this.handleLoad.bind(this);
        this.updatePagination = this.updatePagination.bind(this);
        this.onCreatePaginationLabel = this.onCreatePaginationLabel.bind(this);
    }

    componentDidMount() {
        this.handleLoad();
    }

    handleLoad() {
        const paginationElement = document.getElementsByClassName("pagination")[0];

        function hasClass(element, className) {
            return (element.className).indexOf(className) > -1;
        }
        
        paginationElement.onclick = function(event) {
            if (hasClass(event.target, "page-link")) {
                this.updatePagination(parseInt(event.target.innerText.replace(/\D/g, '')));
            }
            if (event.target.innerText == "Próximo") {
                this.updatePagination(this.props.actualPage + 1);
            }
            if (event.target.innerText == "Anterior") {
                this.updatePagination(this.props.actualPage - 1);
            }
        }.bind(this);

        this.onCreatePaginationField();
    }

    updatePagination (newPage) {
        if (!Number.isInteger(newPage)) return;

        this.props.setPagination(newPage);
        document.getElementById("pagination-manual-number").value = newPage;
    }

  onCreatePaginationField () {
    const parentElement = this.getPaginationBtnParentElement()[0].parentNode;
    const newElement = document.createElement("input");
    newElement.type = 'text';
    newElement.id = "pagination-manual-number";
    newElement.maxLength = "3";
    newElement.onblur = function() { newElement.value = this.props.actualPage; }.bind(this);
    newElement.onkeyup = function() { newElement.value = newElement.value.replace(/\D/g, ''); return this.onChangeManualPagination(newElement); }.bind(this);

    parentElement.insertBefore(newElement, this.getPaginationBtnParentElement()[0]);
    newElement.value = this.props.actualPage;
    this.onCreatePaginationLabel();
  }

  onCreatePaginationLabel () {
    const tag = document.getElementById("pagination-manual-number");
    const newElement = document.createElement("label");
    newElement.innerText = "Página";
    newElement.id = "pagination-manual-label";
    tag.insertAdjacentElement("beforebegin", newElement);
  }

  onChangeManualPagination (newElement) {
    const tagParent = this.getPaginationBtnElement();
    if (tagParent.length >= 1 && newElement.value >= 1 && newElement.value < tagParent.length-1) {
      tagParent[newElement.value].click();
    }
  }

  getPaginationBtnElement () {
      return document.getElementsByClassName("page-link");
  }

  getPaginationBtnParentElement () {
    return document.getElementsByClassName("page-item");
  }

    render() {
        return (
            <></>
        );
    }
}

const mapStateToProps = (state) => ({ actualPage: state.pagination.actualPage });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setPagination }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ManualPagination);