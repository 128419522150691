import React, { Component } from "react";

export default class Menu extends Component {
    constructor(props) {
        super(props);

    }
    render() {

        return (
            <div className="-typetransactions">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="-container-header">
                                <div className="-group-btn-header">
                                    <button type="button" className="btn -active js-saleoff">Liquidação</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}