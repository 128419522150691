import React from "react";

const Loading = () => {
  return (
    <div>
      <div className="spinner-border text-danger" role="status"></div>
      <div className="font-weight-bold">Carregando... Aguarde</div>
    </div>
  );
}

export default Loading;