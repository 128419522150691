import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import iconInfo from '../../../../assets/img/icon-info.svg';
import { setPropertyToCategory } from '../reduce/SelectActions';
import Helpers from '../utils/Helpers';

class FormCategoryParity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sumPoints: 0,
            clubParity: 0,
            parity: 0
        };
        this.handleNumberInputChange = this.handleNumberInputChange.bind(this);
    }

    componentDidMount() {
        if(this.props.categories.length <= 0) return;
        if(this.props.categories.length > 0) {
            this.setState({
                sumPoints: Helpers.sumParities(
                    this.props.categories[this.props.categoryId].parity, 
                    this.props.categories[this.props.categoryId].clubParity
                ),
                parity: this.props.categories[this.props.categoryId].parity,
                clubParity: this.props.categories[this.props.categoryId].clubParity
            })
        }
    }

    handleNumberInputChange = (event) => {
        event.preventDefault();
        const valueMask = Helpers.maskParity(event.target.value)
        this.props.setPropertyToCategory({
            name: event.target.name, 
            value: valueMask, 
            id: this.props.categoryId,
        })
        this.setState({
            [event.target.name]: valueMask,
            [event.target.name + '_error']: '',
            successMsg: ''
        });
        this.setState((state, _) => ({
            sumPoints: Helpers.sumParities(state.parity, state.clubParity),
        }))
    };

    render() {
        return (
            <div className="form__container">
                <div className="div-parity">
                    <div className='input-block input-block-inside-div'>
                        <label htmlFor='esferaParity'>Paridade Parceiro</label>
                        {/* <span> (até 2 caracteres) </span> */}
                        <div class="popover__wrapper">
                            <img src={iconInfo} alt="" />
                            <div class="popover__content">
                                <p class="popover__message">Pontos por Real que o cliente vai receber, pagos pela parceiro.</p>
                            </div>
                        </div>
                        <input
                            type='text'
                            id='parity'
                            name='parity'
                            onChange={this.handleNumberInputChange}
                            value={this.props.categories[this.props.categoryId].parity}
                            maxLength='5'
                        />
                    </div>
                    <div className='error-msg'></div>

                    <div className="div-plus">
                        <span className="plus">+</span>
                    </div>

                    <div className='input-block'>
                        <label htmlFor='partnerParity'>Paridade Clube</label>
                        {/* <span> (até 2 caracteres) </span> */}
                        <div class="popover__wrapper">
                            <img src={iconInfo} alt="" />
                            <div class="popover__content">
                                <p class="popover__message">Pontos por Real que o cliente vai receber a mais por ser clube.</p>
                            </div>
                        </div>
                        <input
                            type='text'
                            id='clubParity'
                            name='clubParity'
                            onChange={this.handleNumberInputChange}
                            value={this.props.categories[this.props.categoryId].clubParity}
                            maxLength='5'
                        />
                    </div>
                    <div className='error-msg'></div>
                </div>
                <span className="sum-points">= &nbsp; <strong>{this.state.sumPoints} pts</strong> &nbsp; a cada R$ 1 Real em compras</span>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ 
    categories: state.select.categories
 });
const mapDispatchToProps = (dispatch) => bindActionCreators({ 
    setPropertyToCategory
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FormCategoryParity);