import React, { Component } from 'react';
import axios from 'axios';
import {
  sessionExpired, 
  generalError, 
  collaboratorFieldNameInvalid, 
  collaboratorFieldEmailInvalid,
  collaboratorFieldIdentifierInvalid,
  collaboratorFieldEmailInvalidFormat,
  collaboratorSuccessCreated
} from '../../../helpers/GlobalMessages'

import { validateEmailFormat, validateCpfFunction } from '../../../helpers/GlobalFunctions'

const initialState = {
  cooperator_name: '',
  cooperator_identifier: '',
  cooperator_email: '',

  cooperator: [],
  disabled_button: false,

  cooperator_name_error: '',
  cooperator_identifier_error: '',
  cooperator_email_error: '',
};

export default class FormCampaign extends Component {
  state = initialState;

  componentDidMount() {
    // const BASE_URL = `${window.REACT_APP_URL}`;

    // axios
    //   .get(`${BASE_URL}/partner`)
    //   .then((response) => {
    //     if (response.data.results) {
    //       this.setState({
    //         cooperator: response.data.results,
    //         disabled_select: false,
    //       });
    //     } else {
    //       this.setState({
    //         cooperator: [],
    //         disabled_select: false,
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     if (error.response && JSON.stringify(error.response.status) === '401') {
    //       this.props.keycloak.logout();
    //     }
    //   });
  }

  validade = () => {
    let cooperator_name_error = "";
    let cooperator_identifier_error = '';
    let cooperator_email_error = "";
    
    if (!this.state.cooperator_name) {
      cooperator_name_error = collaboratorFieldNameInvalid();
    }

    if (!this.state.cooperator_identifier) {
      cooperator_identifier_error = collaboratorFieldIdentifierInvalid();
    }

    if (!this.state.cooperator_email) {
      cooperator_email_error = collaboratorFieldEmailInvalid();
    }

    if (this.state.cooperator_email.length >= 1 &&
      !validateEmailFormat(this.state.cooperator_email)) {
      cooperator_email_error = collaboratorFieldEmailInvalidFormat();
    }

    if (this.state.cooperator_identifier === undefined || this.state.cooperator_identifier.length !== 11) {
      cooperator_identifier_error = collaboratorFieldIdentifierInvalid();
    }
    
    if (!validateCpfFunction(this.state.cooperator_identifier)) {
      cooperator_identifier_error = collaboratorFieldIdentifierInvalid();
    }

    if (cooperator_name_error || cooperator_email_error || cooperator_identifier_error) {
      this.setState({
        cooperator_name_error,
        cooperator_email_error,
        cooperator_identifier_error,
      });
      return false;
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const isValid = this.validade();

    if (isValid) {
      if (this.state.disabled_button) {
        return;
      }

      this.setState({
        disabled_button: true,
      });

      const BASE_URL = `${window.REACT_APP_URL}`;

      const config = {
        headers: {
          'content-type': 'application/json',
        },
      };

      const cooperator = {
        name: this.state.cooperator_name,
        identifier: this.state.cooperator_identifier,
        email: this.state.cooperator_email,
      };
      
      axios
        .post(`${BASE_URL}/collaborator`, cooperator, config)
        .then((response) => {
          this.setState(initialState);
          document.getElementById('form-container').reset();
          this.props.handleSendFormCooperatorSuccess(collaboratorSuccessCreated());
        })
        .catch((error) => {
          this.setState(initialState);
          document.getElementById('form-container').reset();

          if (error.response) {
            const httpStatusError = JSON.stringify(error.response.status);
            if (httpStatusError === '401') {
              this.props.handleSendFormCooperatorFail(sessionExpired());
              return;
            } else {
              const message = error.response?.data?.results?.userMessage;
              if (message) {
                this.props.handleSendFormCooperatorFail(message);
                return;
              }
            }
          }
          this.props.handleSendFormCooperatorFail(generalError());
        }
      );
    }
  };

  handleTextInputChange = (event) => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
      [event.target.name + '_error']: '',
      successMsg: '',
    });
  };

  render() {
    return (
      <div id='page-wrapper'>
        <form
          id='form-container'
          className='container'
          onSubmit={this.handleSubmit.bind(this)}
        >
          <div className='input-block'>
            <label htmlFor='cooperator_name'>Nome do Colaborador</label>
            <input
              type='text'
              id='cooperator_name'
              name='cooperator_name'
              onChange={this.handleTextInputChange}
            />
          </div>
          <div className='error-msg'>{this.state.cooperator_name_error}</div>

          <div className='input-block'>
            <label htmlFor='cooperator_identifier'>CPF do colaborador</label>
            <input
              type='number'
              id='cooperator_identifier'
              name='cooperator_identifier'
              onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              onChange={this.handleTextInputChange}
            />
          </div>
          <div className='error-msg'>{this.state.cooperator_identifier_error}</div>

          <div className='input-block'>
            <label htmlFor='cooperator_email'>Email do Colaborador</label>
            <input
              type='text'
              id='cooperator_email'
              name='cooperator_email'
              onChange={this.handleTextInputChange}
            />
          </div>
          <div className='error-msg'>{this.state.cooperator_email_error}</div>

          <div className='button-wrapper'>
            <button
              type='submit'
              className='button-admin'
              id='send-form'
              disabled={this.state.disabled_button}
            >
              {this.state.disabled_button ? 'Enviando...' : 'Enviar'}
            </button>
          </div>
        </form>
      </div>
    );
  }
}
