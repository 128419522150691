import React, { Component } from "react";

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import setPagination from "../utils/PaginationActions";

import Modal from '../../modal/Modal';

class DateFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            tipo: '',
            active: false,
        };

        this.onClearDateFilter = this.onClearDateFilter.bind(this);
        this.onHiddenApply = this.onHiddenApply.bind(this);
        this.onHidden = this.onHidden.bind(this);
        this.openModal = this.openModal.bind(this);
        this.handleFilterCheckbox = this.handleFilterCheckbox.bind(this);
        this.handleResetPagination = this.handleResetPagination.bind(this);
    }

    handleFilterCheckbox(isActive) {
        isActive ? this.setState({ active: true }) : this.setState({ active: false });
    }

    openModal() {
        this.setState({
            modal: true,
            tipo: 'datapicker',
        });
    }

    onClearDateFilter() {
        if (this.props.tableFilter == "date") {
            this.props.setTableFilter('normal');
            this.props.getDefaultTable()
            .catch((error) => {
                if (error.response && JSON.stringify(error.response.status) === '401') {
                  this.props.keycloak.logout();
                }
            });
        }
    
        if (this.props.tableFilter == "user+date") {
            this.props.getDefaultTable().then(() => {
                setTimeout(() => {
                    this.props.setDatatable(this.props.userFilter);
                    this.props.setTableFilter('user');
                },10);
            })
            .catch((error) => {
                if (error.response && JSON.stringify(error.response.status) === '401') {
                  this.props.keycloak.logout();
                }
            });
        }
        this.handleResetPagination();
        this.handleFilterCheckbox(false);
        this.props.cleanModal();
        this.onHidden();
        return;
    }

    onHiddenApply() {
        if (this.props.tableFilter == "normal" || this.props.tableFilter == "date") {
            this.props.getDefaultTable().then(() => {
                setTimeout(() => {
                    this.props.setDatatable(this.props.dateFilter);
                    this.props.setTableFilter('date');
                },10);
            })
            .catch((error) => {
                if (error.response && JSON.stringify(error.response.status) === '401') {
                  this.props.keycloak.logout();
                }
            });
        }
    
        if (this.props.tableFilter == "user" || this.props.tableFilter == "user+date") {
            this.props.getDefaultTable().then(() => {
                setTimeout(() => {
                    this.props.setDatatable(this.props.userAndDateFilter);
                    this.props.setTableFilter('user+date');
                },10);
            })
            .catch((error) => {
                if (error.response && JSON.stringify(error.response.status) === '401') {
                  this.props.keycloak.logout();
                }
            });
        }
        this.handleResetPagination();
        this.handleFilterCheckbox(true);
        this.onHidden();
        return;
    }

    onHidden() {
        this.setState({
            modal: false,
            tipo: '',
        });
    }

    handleResetPagination() {
        this.props.setPagination(1);
        document.getElementById('pagination-manual-number').value = 1;
    }

    render() {
        return (
            <span className="d-inline custom-control custom-switch">
                {this.state.modal ? (
                    <Modal
                        modal={this.state.modal}
                        metodoHiden={this.onHidden}
                        tipo={this.state.tipo}
                        state={this.props.state}
                        setStateProp={this.props.setStateProp}
                        onHidenApply={this.onHiddenApply}
                        onClearDateFilter={this.onClearDateFilter}
                    />
                ) : null}

                <input type="checkbox" className="custom-control-input" id="date-filter-checkbox" checked={this.state.active} onChange={this.openModal}/>
                <label className='custom-control-label filter-top-table' htmlFor='date-filter-checkbox'>Por data</label>
            </span>
        );
    }
}

const mapStateToProps = (state) => ({ actualPage: state.pagination.actualPage });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setPagination }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);