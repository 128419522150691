import React, { Component } from "react";

export default class CancelSelected extends Component {
    constructor(props) {
        super(props);

        this.state = {
            releasesToCancel: [],
            showCancelModal: false
        }

    }

    hasSelectedReleases() {
        let selectedReleases = this.props.releases.filter(release => {
            return release.selected == true;
        });
        
        return selectedReleases && selectedReleases.length > 0;
    }
    render() {

        return (
            <> 
                {this.props.releases.length > 0 && 
                    <div className="-container-cancel-button">
                        <button disabled={!this.hasSelectedReleases()} className="btn-esfera -secondary -btn-cancel js-openmodalcancellation-saleoff" onClick={this.props.openModal}>
                            Cancelar selecionados
                        </button>
                    </div>
                }
            </>
        )
    }
}