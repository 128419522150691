const INITIAL_STATE = {actualPage: 1}

export default function (state = INITIAL_STATE, action){
    try{
        switch (action.type) {
            case 'PAGINATION_CHANGE':
                return {...state, actualPage: action.payload}
            default:
                return state
        }
    }catch(e){
        return state
    }
}