import React, { Component } from "react";
import axios from "axios";
import moment from 'moment';
import Modal from "./Modal";
import Messages from "./Messages";
import Loading from "./Loading";

var inputFile = '';
export default class Upload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: "",
            dateFormatted: "",
            cvsFile: "",
            csvName: "Selecione arquivo para liquidação",
            showModal: false,
            messageSuccess: false,
            messageFail: false,
            descriptionMessage: "Erro ao enviar o arquivo.",
            loading: false
        }

        this.submitForm = this.submitForm.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeMessage = this.closeMessage.bind(this);

    }

    uploadClick(e) {
        e.preventDefault();
        inputFile.click();
    }

    submitForm() {
        const self = this;
        self.closeModal();
        self.setState({
            messageSuccess: false,
            messageFail: false,
            loading: true
        });

        const formData = new FormData();
        formData.append("file", this.state.cvsFile);
        formData.append("releaseDate", this.state.dateFormatted);

        axios.post(
            `${window.RELEASE_PAYMENT}`,
            formData)
            .then(function (response) {
                const data = response.data;
                if (data && data.results && data.results.messages) {
                    let message = data.results.messages.join('<br />');
                    if (data.results.success) {
                        self.setState({
                            messageSuccess: true,
                            descriptionMessage: message,
                            loading: false,
                            date: "",
                            dateFormatted: "",
                            cvsFile: "",
                            csvName: "Selecione arquivo para liquidação",
                        });
                    } else {
                        self.setState({
                            messageFail: true,
                            descriptionMessage: message,
                            loading: false,
                            date: "",
                            dateFormatted: "",
                            cvsFile: "",
                            csvName: "Selecione arquivo para liquidação",
                        });
                    }

                } else {
                    self.setState({
                        messageFail: true,
                        descriptionMessage: "Erro ao enviar o arquivo.",
                        loading: false
                    });
                }

            })
            .catch(function (error) {
                let httpStatusError = JSON.stringify(error.response.status);
                self.setState({
                    messageFail: true,
                    descriptionMessage: "Erro ao enviar o arquivo.",
                    loading: false
                });

                if (httpStatusError === "401") {
                    self.props.state.keycloak.logout();
                }

            });
    }

    handleDate(event) {
        this.setState({
            date: event.target.value,
            dateFormatted: moment(event.target.value).format("DD-MM-YYYY")
        });
    }

    handleFile(event) {
        if (event.target &&
            event.target.files &&
            event.target.files[0] &&
            event.target.files[0].name) {
            this.setState({
                cvsFile: event.target.files[0],
                csvName: event.target.files[0].name
            });
        }
    }

    openModal() {
        this.setState({ showModal: true });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    closeMessage() {
        this.setState({
            messageSuccess: false,
            messageFail: false
        });
    }

    render() {

        return (
            <>
                <div className="-form-upload-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="-form-send-archive">
                                    <div className="-inputs-container">
                                        <div className="-input-group">
                                            <input
                                                type="date"
                                                className="input-custom"
                                                value={this.state.date}
                                                onChange={this.handleDate}
                                                max="9999-12-31" />
                                            <div className="-info-container-tooltip">
                                                <span className="-info">i</span>
                                                <div className="-info-tooltip util-hidden" >
                                                    <span className="-msg-tooltip">
                                                        Pagamento será efetuado 48 horas após a data setada
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="-input-group">
                                            <input
                                                type="file"
                                                name="file"
                                                id="file"
                                                className="-input-file"
                                                onChange={this.handleFile}
                                                ref={input => {
                                                    inputFile = input;
                                                }}
                                            />
                                            <input
                                                type="text"
                                                name="filename"
                                                id="filename"
                                                className="-input-filename"
                                                placeholder={this.state.csvName}
                                                readOnly />
                                            <input
                                                type="button"
                                                className="btn-esfera -secondary -btn-file"
                                                value="Buscar"
                                                onClick={this.uploadClick} />
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn-esfera -secondary -btn-send"
                                        onClick={this.openModal}
                                        disabled={!this.state.cvsFile || !this.state.date}>
                                        Enviar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    showModal={this.state.showModal}
                    date={this.state.dateFormatted}
                    csvName={this.state.csvName}
                    closeModal={this.closeModal}
                    submitForm={this.submitForm}
                />
                <Messages
                    messageSuccess={this.state.messageSuccess}
                    messageFail={this.state.messageFail}
                    descriptionMessage={this.state.descriptionMessage}
                    closeMessage={this.closeMessage} />
                <Loading loading={this.state.loading} />
            </>
        )
    }
}