import React, { Component } from "react";

export default class Messages extends Component {

    render() {
        return (
            <div className="container">
                {this.props.errorTransactions &&
                    <div className="col-xs-12">
                        <div className="-containermessages">
                            <div className="alert alert-danger" role="alert">
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.props.closeMessageError}>
                                    <span aria-hidden="true">×</span>
                                </button>
                                <div className="-alertmessagecontainer">
                                    <span className="-icomsg -icoerror"></span>
                                    <span className="-alertmessage">{this.props.messageError}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.props.successTransactions &&
                    <div className="col-xs-12">
                        <div className="-containermessages">
                            <div className="alert alert-success" role="alert">
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.props.closeMessageSuccess}>
                                    <span aria-hidden="true">×</span>
                                </button>
                                <div className="-alertmessagecontainer">
                                    <span className="-icomsg -icosuccess"></span>
                                    <span className="-alertmessage">{this.props.messageSuccess}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
