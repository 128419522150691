import React, { Component } from "react";
import ImportIncentiveEngine from "../../components/importIncentiveEngine/ImportIncentiveEngine";

export default class IncentiveEngine extends Component {
  render() {
    return (
      <main className="pt-2 mx-lg-3" id="main-container">
        <div className="container-fluid mt-4">
          <ImportIncentiveEngine
            keycloak={this.props.keycloak}
            logout={this.props.logout}
            isAdmin={this.props.isAdmin}
            isAdminCashbackFiles={this.props.isAdminCashbackFiles}
            isBackofficeCashbackFiles={this.props.isBackofficeCashbackFiles}
            company={this.props.company}
          />
        </div>
      </main>
    );
  }
}
